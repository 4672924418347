import { useAuth0 } from "@auth0/auth0-react"
import { useMutation } from "react-query"
import { toast } from 'react-toastify';
import { AccountsService } from "src/services/accounts.service";


interface AccountUploadLogoRequest{
    file: File;
    accountId: string; 
} 

export const useAccountUploadLogo = () => {
    const auth0 = useAuth0();
    return useMutation({
        mutationKey: Math.random().toString(), // prevent caching. call every time
        mutationFn: async (request: AccountUploadLogoRequest) => {
            console.log(`useAccountUploadLogo invoked for: ${request.file.name}`);
            const fileExtension = request.file.name.split('.').pop() || 'jpg';

            const toastId = toast('Preparing to upload logo', { type: 'info' , autoClose: false });
            const uploadUrlResponse = await AccountsService.getAccountLogoUploadUrl(request.accountId, fileExtension , auth0);
            console.log(`received upload url: ${uploadUrlResponse.uploadUrl}`);
            
            toast.update(toastId, { render: 'Uploading logo' });
            await AccountsService.uploadFileToSignedUrl(request.file, uploadUrlResponse.uploadUrl, (progress) => { console.log(`file upload progress: ${progress}%`)});
            console.log('file uploaded successfully');

            toast.update(toastId, { render: 'Updating account'});
            let account = await AccountsService.updateAccount(request.accountId, {logo: uploadUrlResponse.fileName}, auth0);
            console.log('account updated', account);
            
            toast.update(toastId, { type: 'success', render: `Account logo updated`, autoClose: 4000 });
            return account;
        }
    })
};