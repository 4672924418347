import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"
import { ConsultantsService } from "src/services/consultants.service"


export const useConsultants = (() => {
    const auth0 = useAuth0();
    
    return useQuery({
        queryKey: 'consultants',
        staleTime: 120000,
        queryFn: async () => {
            const consultants = await ConsultantsService.getConsultants(auth0);
            return consultants;
        }
    })
});