import { useAuth0 } from "@auth0/auth0-react"
import { useMutation } from "react-query"
import { ParticipantsService } from "src/services/participants.service";

export const useDeleteParticipant = () => {
    const auth0 = useAuth0();
    return useMutation({
        mutationFn: async (participantId: string) => {
            console.log('useDeleteParticipant invoked');
            await ParticipantsService.deleteParticipant(participantId, auth0);
        }
    })
};