import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "react-query";
import { ParticipantsService } from "src/services/participants.service";
import { toast } from "react-toastify";
import { ReportEntry } from "src/models/shared/participants";

interface ParticipantExportReportPPTXRequest {
  participantId: string;
  reportEntry: ReportEntry;
}

export const useParticipantExportReportPPTX = () => {
  const auth0 = useAuth0();
  return useMutation({
    mutationKey: Math.random().toString(), // prevent caching. call every time
    mutationFn: async (request: ParticipantExportReportPPTXRequest) => {
      console.log(
        "useParticipantExportReportPPTX invoked for:",
        request.reportEntry
      );
      const toastId = toast(
        "Exporting report to PPTX.\nThis may take 30 seconds",
        {
          type: "info",
          autoClose: false,
        }
      );
      try {
        const exportResult =
          await ParticipantsService.exportParticipantReportToPPTX(
            request.participantId,
            request.reportEntry,
            auth0
          );
        console.log(`received export result reportEntry:`, exportResult);

        toast.update(toastId, {
          type: "success",
          render: `Report exported to PPTX`,
          autoClose: 4000,
        });
        return exportResult;
      } catch (err) {
        toast.update(toastId, {
          type: "error",
          render: `An error occurred exporting the report to PPTX`,
          autoClose: false,
        });
        return null;
      }
    },
  });
};
