import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"
import { AccountsDashboardData, AccountsService } from "src/services/accounts.service"
import { ParticipantsDashboardData, ParticipantsService } from "src/services/participants.service";
import { ProjectsDashboardData, ProjectsService } from "src/services/projects.service";


export const useDashboardData = (() => {
    const auth0 = useAuth0();
   
    return useQuery({
        queryKey: 'dashboard-data',
        queryFn: async () => {
            const results = await Promise.all([
                AccountsService.getAccountsDashboardData(auth0),
                ProjectsService.getProjectsDashboardData(auth0),
                ParticipantsService.getParticipantsDashboardData(auth0)
            ]);
            return {
                accounts: results[0] as AccountsDashboardData,
                projects: results[1] as ProjectsDashboardData,
                participants: results[2] as ParticipantsDashboardData
            };
        }
    })
});