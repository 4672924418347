import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"
import { ParticipantsService } from "src/services/participants.service";

export const useParticipants = ((accountId?: string, projectId?: string) => {
    const auth0 = useAuth0();
    console.log(`useParticipants: ${accountId} ${projectId}`);
    return useQuery({
        queryKey: ['participants', accountId, projectId],
        queryFn: async () => {
            const participants = accountId && projectId ? await ParticipantsService.getParticipants(accountId, projectId, auth0) : [];
            return participants;
        }
    })
});