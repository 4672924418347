import styles from './index.module.scss';
import { Project } from "src/models/shared/projects";

import Select, {
  SingleValue,
} from 'react-select';

import { TemplateChoice } from "src/models/shared/projects";
import { toast } from "react-toastify";
import { useTemplateChoices } from "src/hooks/use-template-choices";

type OptionType = {value: TemplateChoice, label: string};

interface ComponentProps {
  project: Project;
  onTemplateSelectChanged: (project: Project) => Promise<void>
}

export const TemplateSelect = ({
  project,
  onTemplateSelectChanged
}: ComponentProps): JSX.Element => {
  const { data: templates, isError: templatesIsError, error: templatesError } = useTemplateChoices();

  const error = templatesIsError ? `Error fetching templates: ${templatesError}` : undefined;
  if(error) {
    toast.error(`An error occurred fetching templates list: ${error.toString()}`);
  }

  if(!project.template && templates) {
    project.template = templates[0];
  }

  const onChange = (newValue: SingleValue<OptionType>) => {
    project.template = newValue!.value;
    onTemplateSelectChanged(project);
  }

  const templateOptions : OptionType[] = (templates || []).map(t => ({ 
    value: t,
    label: t.name
  }));

  return (
    <div className={styles['template-select']}>
      <div className={styles['template-select-title']}>
        Template:
      </div>  
      <div className={styles['template-select-list']}>
        <Select
          captureMenuScroll={true}
          options={templateOptions}
          defaultValue={ {value: project.template , label: project.template?.name} as OptionType }
          onChange={onChange}
        />
      </div>
    </div>
  );
};

/*TemplateSelect.propTypes = {
  project: Project,
};*/
