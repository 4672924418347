import { useAuth0 } from "@auth0/auth0-react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { PageLoader } from "./components/PageLoader/PageLoader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { Route, Routes } from "react-router-dom";
import { CallbackPage } from "./screens/CallbackPage";
import { NotFoundPage } from "./screens/NotFoundPage/NotFoundPage";
import { AccountsPage } from "./screens/AccountsPage";
import { ProjectsPage } from "./screens/ProjectsPage";
import { DashboardPage } from "./screens/DashboardPage";
import { Account } from "./models/shared/accounts";
import { Project } from "./models/shared/projects";
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export interface IAppContext {
  currentAccount: Account | undefined;
  setCurrentAccount: Dispatch<SetStateAction<Account | undefined>>;
  currentProject: Project | undefined;
  setCurrentProject: Dispatch<SetStateAction<Project | undefined>>;
}

export const AppContext = React.createContext<IAppContext>({
  currentAccount: undefined,
  setCurrentAccount: () => {},
  currentProject: undefined,
  setCurrentProject: () => {},
})

export const App: React.FC = () => {
  const auth0 = useAuth0();
  const [currentAccount, setCurrentAccount] = useState<Account | undefined>();
  const [currentProject, setCurrentProject] = useState<Project | undefined>();

  const contextValue = React.useMemo(() => ({
    currentAccount, setCurrentAccount,  
    currentProject, setCurrentProject,
  }), [currentAccount, currentProject]);

  useEffect(() => {  
    if(currentAccount) {
      console.log(`last-account set to: ${currentAccount._id}`)
      localStorage.setItem('last-account', currentAccount._id);
    }
    if(currentProject) {
      console.log(`last-project set to: ${currentProject._id}`)
      localStorage.setItem('last-project', currentProject._id);
    }
  }, [currentAccount,currentProject]);

  if (auth0.isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
      <AppContext.Provider value={contextValue}>
        <Routes>
          <Route path="/" element={<AuthenticationGuard component={DashboardPage} />}/>
          <Route path="/accounts" element={<AuthenticationGuard component={AccountsPage} />}/>
          <Route path="/projects" element={<AuthenticationGuard component={ProjectsPage} />}/>

          <Route path="/callback" element={<CallbackPage />} />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          stacked 
          theme="dark"
        />
      </AppContext.Provider>
  );
};
