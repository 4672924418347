import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { TopBar } from "../../components/TopBar";
// import "./style.css";

import cn from "classnames";
import styles from "./index.module.scss";
import { safename } from "safename";
import { DateTime } from "luxon";

import { Project } from "../../models/shared/projects";
import { ReactComponent as ProjectIcon } from "../../icons/eos-icons--project-outlined.svg";
import { ReactComponent as ParticipantsIcon } from "../../icons/material-symbols--person-outline.svg";
//import { ReactComponent as DownloadIcon } from "../../icons/material-symbols--download.svg";
import { ReactComponent as PPTXIcon } from "../../icons/bi--filetype-pptx.svg";
import { ReactComponent as PDFIcon } from "../../icons/bi--filetype-pdf.svg";

import { ReactComponent as RefreshIcon } from "../../icons/material-symbols--refresh.svg";
//import { ReactComponent as DropRightIcon } from '../../icons/ri--arrow-drop-right-fill.svg'
//import { ReactComponent as DropDownIcon } from '../../icons/ri--arrow-drop-down-fill.svg'
import { ReactComponent as SettingsIcon } from "../../icons/clarity--settings-line.svg";
import { ReactComponent as WarningIcon } from "../../icons/material-symbols--warning-outline.svg";
import { ReactComponent as TrashIcon } from "../../icons/iconamoon--trash.svg";

import { AppContext } from "src/app";
import { useProjects } from "src/hooks/use-projects";
import { useAccounts } from "src/hooks/use-accounts";
import { useParticipants } from "src/hooks/use-participants";
import { Participant } from "src/models/shared/participants";
import { useParticipantReportSignedUrls } from "src/hooks/use-participant-report-signed-urls";
import { useParticipantUploadInputReport } from "src/hooks/use-participant-upload-report";
import InlineEdit from "@atlaskit/inline-edit";
import Textfield from "@atlaskit/textfield";
import TextArea from "@atlaskit/textarea";
import { toast } from "react-toastify";
import { useUpdateProject } from "src/hooks/use-update-project";
import { useParticipantReprocessReport } from "src/hooks/use-participant-reprocess-report";
import { ProjectSettings } from "src/components/ProjectSettings";
import { TraitId } from "src/models/shared/traits";
import { useNavigate } from "react-router-dom";
import { useParticipantUploadPhoto } from "src/hooks/use-participant-upload-photo";
import { Consultants } from "src/components/Consultants";
import { TemplateSelect } from "src/components/TemplateSelect";
import { useDeleteParticipant } from "src/hooks/use-delete-participant";
import { TemplateVariantSelect } from "src/components/TemplateVariantSelect";
import { useUpdateParticipant } from "src/hooks/use-update-participant";
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from "@atlaskit/dropdown-menu";
import { useParticipantExportReportPPTX } from "src/hooks/use-participant-export-report-pptx";

export const ProjectsPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { isError: accountsIsError, error: accountsError } = useAccounts();
  const { currentAccount, currentProject, setCurrentProject } =
    useContext(AppContext);
  const {
    data: projects,
    isError: projectsIsError,
    error: projectsError,
    refetch: refetchProjects,
  } = useProjects(currentAccount?._id);
  const {
    data: participants,
    isError: participantsIsError,
    error: participantsError,
    refetch: refetchParticipants,
  } = useParticipants(currentAccount?._id, currentProject?._id);
  const { mutateAsync: invokeParticipantReportSignedUrls } =
    useParticipantReportSignedUrls();
  const { mutateAsync: invokeParticipantUploadReport } =
    useParticipantUploadInputReport();
  const { mutateAsync: invokeParticipantUploadPhoto } =
    useParticipantUploadPhoto();
  const { mutateAsync: invokeParticipantReprocessReport } =
    useParticipantReprocessReport();
  const { mutateAsync: invokeDeleteParticipant } = useDeleteParticipant();
  const { mutateAsync: invokeUpdateParticipant } = useUpdateParticipant();
  const { mutateAsync: invokeUpdateProject } = useUpdateProject();
  const { mutateAsync: invokeParticipantExportReportPPTX } =
    useParticipantExportReportPPTX();

  // because saving project (e.g. save changed value) causes a full refresh, we need to persist the state of the expanded setting "elsewhere" as long as the project id has not changed.
  // once project id changed, default again to false
  if (
    localStorage.getItem("projects-settings-project-id") !== currentProject?._id
  ) {
    localStorage.setItem("projects-settings-expanded", "false");
  }
  const [settingsExpanded, setSettingsExpanded] = useState<boolean>(
    localStorage.getItem("projects-settings-expanded") === "true"
  );

  localStorage.setItem(
    "projects-settings-project-id",
    currentProject?._id || "null"
  );
  useEffect(() => {
    localStorage.setItem(
      "projects-settings-expanded",
      settingsExpanded.toString()
    );
  }, [settingsExpanded]);

  const error = accountsIsError
    ? `Error fetching accounts: ${accountsError}`
    : projectsIsError
    ? `Error fetching projects: ${projectsError}`
    : participantsIsError
    ? `Error fetching participants: ${participantsError}`
    : undefined;

  const handleProjectClick = async (project: Project) => {
    setCurrentProject(project);
  };

  const handleReportDownloadClick = async (
    participant: Participant,
    slideSetId?: string
  ) => {
    const signedUrls = await invokeParticipantReportSignedUrls(participant._id);
    const safeFileName = safename(participant.name, "-") + ".pdf";
    console.log("got signed urls:", signedUrls, safeFileName);
    if (signedUrls.reportUrls) {
      const reportUrlEntry = signedUrls.reportUrls.find(
        // eslint-disable-next-line eqeqeq
        (e) => e.slideSet == slideSetId
      );
      if (reportUrlEntry) {
        const link = document.createElement("a");
        link.href = reportUrlEntry.reportUrl;
        link.download = safeFileName;
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      }
    }
  };

  const handleReportDownloadPPTXClick = async (
    participant: Participant,
    slideSetId?: string
  ) => {
    const reportEntry = participant.generatedReports!.find(
      // eslint-disable-next-line eqeqeq
      (re) => re.slideSet == slideSetId
    );
    if (reportEntry) {
      if (!reportEntry.pptxFileKey) {
        const updatedReportEntry = await invokeParticipantExportReportPPTX({
          participantId: participant._id,
          reportEntry,
        });
        reportEntry.pptxFileKey = updatedReportEntry?.pptxFileKey;
      }

      const signedUrls = await invokeParticipantReportSignedUrls(
        participant._id
      );
      const safeFileName = safename(participant.name, "-") + ".pptx";
      console.log("got signed urls:", signedUrls, safeFileName);
      if (signedUrls.reportUrls) {
        const reportUrlEntry = signedUrls.reportUrls.find(
          // eslint-disable-next-line eqeqeq
          (e) => e.slideSet == slideSetId
        );
        if (reportUrlEntry && reportUrlEntry.pptxUrl) {
          const link = document.createElement("a");
          link.href = reportUrlEntry.pptxUrl;
          link.download = safeFileName;
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
        }
      }
    }
  };

  const handleReportRefreshClick = async (participant: Participant) => {
    await invokeParticipantReprocessReport({
      participantId: participant._id,
      participantName: participant.name,
    });
    refetchParticipants();
  };

  const handleDeleteReportClick = async (participant: Participant) => {
    await invokeDeleteParticipant(participant._id);
    refetchParticipants();
  };

  const handleUploadReportChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const file = event.target.files[0];
      await invokeParticipantUploadReport({
        file,
        accountId: currentAccount!._id,
        projectId: currentProject!._id,
      });
      refetchParticipants();
      event.target.value = ""; // to reset the input field so same file can fire onChange again
    }
  };

  const handleDeleteParticipantPhoto = async (participant: Participant) => {
    if (
      window.confirm(
        `Are you sure you want to remove ${participant.name}'s photo?`
      )
    ) {
      await invokeUpdateParticipant({
        participantId: participant._id,
        updates: {
          image: null,
        },
      });
      await invokeParticipantReprocessReport({
        participantId: participant._id,
        participantName: participant.name,
      });
      refetchParticipants();
    }
  };

  const handleUploadParticipantPhoto = async (
    event: ChangeEvent<HTMLInputElement>,
    participant: Participant
  ) => {
    if (event.target.files) {
      const file = event.target.files[0];
      console.log("file:", file);
      await invokeParticipantUploadPhoto({ file, participant });
      refetchParticipants();
      event.target.value = ""; // to reset the input field so same file can fire onChange again
    }
  };
  const saveProjectName = async (project: Project, value: string) => {
    if (value === project?.name) return;
    console.log("saveProjectName", value);
    await _saveProjectChanges(project, {
      name: value,
    });
  };

  const saveProjectDescription = async (project: Project, value: string) => {
    if (value === currentProject?.description) return;
    console.log("saveProjectNotes", value);
    await _saveProjectChanges(project, {
      description: value,
    });
  };

  const saveProjectTraitModelValueChanged = async (
    project: Project,
    traitId: TraitId,
    settingName: "weight" | "lowThreshold" | "highThreshold",
    settingValue: number
  ) => {
    await _saveProjectChanges(project, {
      [`traitModel.${traitId.toString()}.${settingName}`]: settingValue,
    });
  };

  const saveCustomValuesChanged = async (project: Project) => {
    await _saveProjectChanges(project, {
      customValues: project.customValues,
    });
  };

  const onCustomSlidesChanged = async (project: Project) => {
    await _saveProjectChanges(project, {
      customSlides: project.customSlides,
    });
  };

  const onSlideSetsChanged = async (project: Project, refresh: boolean) => {
    await _saveProjectChanges(
      project,
      {
        slideSets: project.slideSets,
      },
      refresh
    );
  };

  const saveConsultantsChanged = async (project: Project) => {
    await _saveProjectChanges(project, {
      consultants: project.consultants,
    });
  };
  const saveTemplateSelectChanged = async (project: Project) => {
    await _saveProjectChanges(project, {
      template: project.template,
    });
  };

  const _saveProjectChanges = async (
    project: Project,
    updates: Partial<Project>,
    refresh: boolean = true
  ) => {
    try {
      //const toastId = toast('Saving Changes');
      const updated = await invokeUpdateProject({
        projectId: project._id,
        updates,
      });
      if (refresh) {
        setCurrentProject(updated);
        await refetchProjects();
      }
      //toast.dismiss(toastId);
    } catch (err) {
      toast("An error occurred saving changes", {
        type: "error",
        autoClose: false,
      });
    }
  };

  const saveParticipantTemplateVariantChanged = async (
    participant: Participant
  ) => {
    try {
      await invokeUpdateParticipant({
        participantId: participant._id,
        updates: {
          templateVariantId: participant.templateVariantId,
        },
      });
      await invokeParticipantReprocessReport({
        participantId: participant._id,
        participantName: participant.name,
      });
      refetchParticipants();
    } catch (err) {
      toast("An error occurred saving changes", {
        type: "error",
        autoClose: false,
      });
    }
  };

  const toggleAdvancedSettingsDisplay = () => {
    setSettingsExpanded(!settingsExpanded);
  };

  const titleTextFieldStyles = {
    font: '400 28px/1.21 "Inter", Helvetica, Arial, serif',
  };
  const standardTextAreaStyles = {
    font: '400 16px/1.2 "Inter", Helvetica, Arial, serif',
  };

  return (
    <div className={cn(styles.root, "projects-screen", "projects")}>
      <TopBar className="top-bar-instance" />
      {error && <div className={styles["error-message"]}>{error}</div>}
      <div className={styles["main-content-container"]}>
        <div className={styles["left-column-container"]}>
          <div
            className={styles["back-to-accounts"]}
            onClick={() => navigate("/accounts")}
          >
            &lt; All Accounts
          </div>

          <div className={styles["left-column-top-content"]}>
            <div className={styles["account-logo-container"]}>
              {currentAccount?.logo && (
                <img
                  className={styles["account-logo-image"]}
                  src={currentAccount?.logo}
                  alt="alt text"
                />
              )}
            </div>

            <div className={styles["account-name-container"]}>
              {currentAccount?.name || "New Account"}
            </div>
          </div>

          <div className={styles["left-column-content"]}>
            <div className={styles["projects-list-container"]}>
              <div className={styles["projects-list-title"]}>
                <h2 className={styles["title-text"]}>Projects</h2>
              </div>

              <div className={styles["projects-list"]}>
                {(projects || []).map((project) => (
                  <h2
                    key={project._id}
                    className={
                      currentProject?._id === project._id
                        ? styles["projects-list-item-active"]
                        : styles["projects-list-item"]
                    }
                    onClick={() => handleProjectClick(project)}
                  >
                    {project.name || "New Project"}
                  </h2>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className={styles["right-column-container"]}>
          <div className={styles["right-column-header"]}>
            <div className={styles["total-records-container"]}>
              <div className={styles["total-records-label"]}>
                Total Reports:{" "}
              </div>
              <div className={styles["total-records-value"]}>
                {participants?.length || 0}
              </div>
            </div>
          </div>

          <div className={styles["right-column-content-container"]}>
            <div className={styles["project-details-container"]}>
              <div className={styles["project-details-main"]}>
                <div className={styles["project-details-title-row"]}>
                  <div className={styles["project-details-name-container"]}>
                    <ProjectIcon className={styles["project-details-icon"]} />
                    <div className={styles["project-details-title"]}>
                      <InlineEdit
                        defaultValue={currentProject?.name}
                        editView={({ errorMessage, ...fieldProps }) => (
                          <Textfield
                            isCompact={true}
                            {...fieldProps}
                            autoFocus
                            style={titleTextFieldStyles}
                            width={"300px"}
                          />
                        )}
                        readView={() => (
                          <div className={styles["read-view-content"]}>
                            {currentProject?.name ||
                              "Click to enter project name"}
                          </div>
                        )}
                        onConfirm={(value) => {
                          saveProjectName(currentProject!, value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles["project-details-title-row"]}>
                  <div
                    className={styles["project-details-description-container"]}
                  >
                    <div
                      className={styles["project-details-description-label"]}
                    >
                      Notes:
                    </div>
                    <div className={styles["project-details-description-edit"]}>
                      <InlineEdit
                        defaultValue={currentProject?.description}
                        editView={({ errorMessage, ...fieldProps }, ref) => (
                          // @ts-ignore - textarea does not pass through ref as a prop
                          <TextArea
                            {...fieldProps}
                            ref={ref}
                            isCompact={true}
                            style={standardTextAreaStyles}
                          />
                        )}
                        readView={() => (
                          <div className={styles["read-view-content"]}>
                            {currentProject?.description ||
                              "Click to enter project description"}
                          </div>
                        )}
                        onConfirm={(value) => {
                          saveProjectDescription(currentProject!, value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {currentProject && (
                  <div className={styles["project-details-title-row"]}>
                    <div
                      className={
                        styles["project-details-consultants-container"]
                      }
                    >
                      <Consultants
                        project={currentProject}
                        onConsultantsChanged={saveConsultantsChanged}
                      />
                    </div>
                  </div>
                )}
                {currentProject && (
                  <div className={styles["project-details-title-row"]}>
                    <div
                      className={styles["project-details-templates-container"]}
                    >
                      <TemplateSelect
                        project={currentProject}
                        onTemplateSelectChanged={saveTemplateSelectChanged}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className={styles["project-advanced-settings-container"]}>
                <div
                  className={styles["project-advanced-settings-section-title"]}
                >
                  {/*settingsExpanded ? 
                        <DropDownIcon className={styles['drop-icon']} onClick={toggleAdvancedSettingsDisplay} />
                        :
                        <DropRightIcon className={styles['drop-icon']} onClick={toggleAdvancedSettingsDisplay} />*/}
                  <SettingsIcon
                    className={styles["settings-icon"]}
                    onClick={toggleAdvancedSettingsDisplay}
                  />
                  <div
                    className={styles["project-advanced-settings-title"]}
                    onClick={toggleAdvancedSettingsDisplay}
                  >
                    Project Settings
                  </div>
                </div>
                {currentProject && settingsExpanded && (
                  <div className={styles["expanded-content-container"]}>
                    <ProjectSettings
                      project={currentProject}
                      onTraitModelValueChanged={
                        saveProjectTraitModelValueChanged
                      }
                      onCustomValuesChanged={saveCustomValuesChanged}
                      onCustomSlidesChanged={onCustomSlidesChanged}
                      onSlideSetsChanged={onSlideSetsChanged}
                    />
                  </div>
                )}
              </div>

              <div className={styles["project-participants-container"]}>
                <div className={styles["project-details-participants"]}>
                  <div
                    className={styles["project-details-participants-header"]}
                  >
                    <div
                      className={
                        styles["project-participants-cell-name"] +
                        " " +
                        styles["project-participants-header-cell"]
                      }
                    >
                      <ParticipantsIcon
                        className={styles["participants-icon"]}
                      />
                      <h1 className={styles["title-text"]}>Reports</h1>
                    </div>
                    <div
                      className={
                        styles["project-participants-cell-count"] +
                        " " +
                        styles["project-participants-header-cell"]
                      }
                    ></div>
                    <div
                      className={
                        styles["project-participants-cell-date"] +
                        " " +
                        styles["project-participants-header-cell"]
                      }
                    >
                      <label className={styles["new-report-button"]}>
                        <input
                          type="file"
                          accept=".pdf"
                          onChange={handleUploadReportChange}
                        />
                        New Report
                      </label>
                    </div>
                  </div>

                  {participants && participants.length > 0 ? (
                    <div
                      className={styles["project-details-participants-rows"]}
                    >
                      {participants.map((participant) => (
                        <div
                          key={participant._id}
                          className={styles["project-participants-row"]}
                        >
                          <div
                            className={
                              styles["project-participants-cell-picture"]
                            }
                          >
                            {participant.image && (
                              <TrashIcon
                                title="Delete photo"
                                className={styles["participant-trash-icon"]}
                                onClick={() =>
                                  handleDeleteParticipantPhoto(participant)
                                }
                              />
                            )}
                            <label>
                              <input
                                type="file"
                                accept=".jpg,.jpeg,.png"
                                onChange={async (
                                  event: ChangeEvent<HTMLInputElement>
                                ) => {
                                  return handleUploadParticipantPhoto(
                                    event,
                                    participant
                                  );
                                }}
                              />
                              <img
                                src={
                                  participant.image ||
                                  "/assets/images/no-profile-img.jpg"
                                }
                                alt="profile"
                              ></img>
                            </label>
                          </div>
                          <div
                            className={styles["project-participants-cell-name"]}
                          >
                            {participant.name || "Name Missing"}
                          </div>
                          <div
                            className={
                              styles["project-participants-cell-count"]
                            }
                          >
                            {currentProject &&
                              currentProject.template.variants.length > 1 && (
                                <TemplateVariantSelect
                                  template={currentProject.template}
                                  participant={participant}
                                  onSelectChanged={
                                    saveParticipantTemplateVariantChanged
                                  }
                                />
                              )}
                            {participant.generatedReports ? (
                              participant.generatedReports.length === 1 ? (
                                <>
                                  <PDFIcon
                                    title="Download Report"
                                    className={styles["download-icon"]}
                                    onClick={() =>
                                      handleReportDownloadClick(
                                        participant,
                                        undefined
                                      )
                                    }
                                  />
                                  <PPTXIcon
                                    title="Download Report as PPTX (export)"
                                    className={`${styles["download-icon"]} ${
                                      participant.generatedReports[0]
                                        .pptxFileKey
                                        ? styles["download-generated"]
                                        : styles["download-not-generated"]
                                    }`}
                                    onClick={() =>
                                      handleReportDownloadPPTXClick(
                                        participant,
                                        undefined
                                      )
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <DropdownMenu<HTMLButtonElement>
                                    trigger={({ triggerRef, ...props }) => (
                                      <button {...props} ref={triggerRef}>
                                        <PDFIcon
                                          title="Download Report"
                                          className={styles["download-icon"]}
                                        />
                                      </button>
                                    )}
                                    spacing="compact"
                                    shouldRenderToParent={true}
                                  >
                                    <DropdownItemGroup>
                                      {participant.generatedReports.map(
                                        (report) => (
                                          <DropdownItem
                                            onClick={() =>
                                              handleReportDownloadClick(
                                                participant,
                                                report.slideSet
                                              )
                                            }
                                          >
                                            {report.slideSet
                                              ? currentProject?.slideSets.find(
                                                  (s) =>
                                                    s._id === report.slideSet
                                                )?.name || "[missing slide set]"
                                              : "Default"}
                                          </DropdownItem>
                                        )
                                      )}
                                    </DropdownItemGroup>
                                  </DropdownMenu>
                                  <DropdownMenu<HTMLButtonElement>
                                    trigger={({ triggerRef, ...props }) => (
                                      <button {...props} ref={triggerRef}>
                                        <PPTXIcon
                                          title="Download Report as PPTX (export)"
                                          className={styles["download-icon"]}
                                        />
                                      </button>
                                    )}
                                    spacing="compact"
                                    shouldRenderToParent={true}
                                  >
                                    <DropdownItemGroup>
                                      {participant.generatedReports.map(
                                        (report) => (
                                          <DropdownItem
                                            onClick={() =>
                                              handleReportDownloadPPTXClick(
                                                participant,
                                                report.slideSet
                                              )
                                            }
                                          >
                                            <span
                                              className={
                                                report.pptxFileKey
                                                  ? styles["download-generated"]
                                                  : styles[
                                                      "download-not-generated"
                                                    ]
                                              }
                                            >
                                              {report.slideSet
                                                ? currentProject?.slideSets.find(
                                                    (s) =>
                                                      s._id === report.slideSet
                                                  )?.name ||
                                                  "[missing slide set]"
                                                : "Default"}
                                            </span>
                                          </DropdownItem>
                                        )
                                      )}
                                    </DropdownItemGroup>
                                  </DropdownMenu>
                                </>
                              )
                            ) : participant.reportError ? (
                              <>
                                <WarningIcon
                                  title={participant.reportError}
                                  className={styles["warning-icon"]}
                                />
                                <div className={styles["no-icon"]}></div>
                              </>
                            ) : (
                              <>
                                <div className={styles["no-icon"]}></div>
                                <div className={styles["no-icon"]}></div>
                              </>
                            )}
                            <RefreshIcon
                              title="Re-process Report"
                              className={styles["refresh-icon"]}
                              onClick={() =>
                                handleReportRefreshClick(participant)
                              }
                            />
                            <TrashIcon
                              title="Delete report"
                              className={styles["trash-icon"]}
                              onClick={() =>
                                handleDeleteReportClick(participant)
                              }
                            />
                          </div>
                          <div
                            className={styles["project-participants-cell-date"]}
                          >
                            {_formatDate(participant.createdAt)}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className={styles["project-participants-not-found"]}>
                      No Reports
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function _formatDate(date: string | Date) {
  if (typeof date === "string") {
    return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT);
  } else {
    return DateTime.fromJSDate(date).toLocaleString(DateTime.DATETIME_SHORT);
  }
}
