import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "react-query";
import { Participant } from "src/models/shared/participants";
import { ParticipantsService } from "src/services/participants.service";

interface UpdateParticipantRequest {
  participantId: string;
  updates: Partial<Participant>;
}

export const useUpdateParticipant = () => {
  const auth0 = useAuth0();
  return useMutation({
    mutationFn: async (request: UpdateParticipantRequest) => {
      console.log(
        `useUpdateParticipant invoked for: ${request.participantId}`,
        request.updates
      );
      const participant = await ParticipantsService.updateParticipant(
        request.participantId,
        request.updates,
        auth0
      );
      return participant;
    },
  });
};
