/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import styles from "./index.module.scss";
import { Project } from "src/models/shared/projects";
import {
  TraitId,
  TraitType,
  allTraitIds,
  traitAttributes,
} from "src/models/shared/traits";
import InlineEdit from "@atlaskit/inline-edit";
import Textfield from "@atlaskit/textfield";
import PropTypes from "prop-types";

interface Props {
  project: Project;
  traitType: TraitType;
  onTraitModelValueChanged: (
    project: Project,
    traitId: TraitId,
    settingName: "weight" | "lowThreshold" | "highThreshold",
    settingValue: number
  ) => Promise<void>;
}

export const TraitSettings = ({
  project,
  traitType,
  onTraitModelValueChanged,
}: Props): JSX.Element => {
  const traits = allTraitIds.filter(
    (t) => traitAttributes.get(t)?.type === traitType
  );

  const saveTraitSetting = async (
    trait: TraitId,
    settingName: "weight" | "lowThreshold" | "highThreshold",
    settingValue: number
  ) => {
    console.log("saveTraitSetting", trait, settingName, settingValue);
    if (project.traitModel[trait][settingName] !== settingValue) {
      console.log("fire traitSettingChanged", trait, settingName, settingValue);
      project.traitModel[trait][settingName] = settingValue;
      await onTraitModelValueChanged(project, trait, settingName, settingValue);
    }
  };

  const standardTextFieldStyles = {
    font: '400 16px/1.2 "Inter", Helvetica, Arial, serif',
  };

  return (
    <div className={styles["trait-settings-container"]}>
      <div
        className={
          styles["trait-settings-title-row"] +
          " " +
          styles["trait-label-" + traitType.toString().toLowerCase()]
        }
      >
        {traitType.toString()} Settings
      </div>
      <div className={styles["trait-settings-table-container"]}>
        <table cellSpacing={0} className={styles["trait-settings-table"]}>
          <thead>
            <tr>
              <th className={styles["first"]}></th>
              {traits.map((trait) => (
                <th key={`header-${trait}`}>
                  {
                    traitAttributes
                      .get(trait)
                      ?.label.find((l) => l.language === "en")!.text
                  }
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {false /* weight is not used in project generation. hiding its UI to avoid confusion */ && (
              <tr>
                <td className={styles["first"]}>Weight</td>
                {traits.map((trait) => (
                  <td key={`weight-${trait}`}>
                    <InlineEdit
                      defaultValue={project.traitModel[trait].weight}
                      editView={({ errorMessage, ...fieldProps }) => (
                        <Textfield
                          isCompact={true}
                          {...fieldProps}
                          autoFocus
                          style={standardTextFieldStyles}
                        />
                      )}
                      readView={() => (
                        <div
                          className={styles["trait-settings-table-read-view"]}
                        >
                          {project.traitModel[trait].weight}
                        </div>
                      )}
                      onConfirm={(value) => {
                        saveTraitSetting(trait, "weight", Number(value));
                      }}
                    />
                  </td>
                ))}
              </tr>
            )}

            <tr>
              <td className={styles["first"]}>Low</td>
              {traits.map((trait) => (
                <td key={`low-${trait}`}>
                  <InlineEdit
                    defaultValue={project.traitModel[trait].lowThreshold}
                    editView={({ errorMessage, ...fieldProps }) => (
                      <Textfield
                        isCompact={true}
                        {...fieldProps}
                        autoFocus
                        style={standardTextFieldStyles}
                      />
                    )}
                    readView={() => (
                      <div className={styles["trait-settings-table-read-view"]}>
                        {project.traitModel[trait].lowThreshold}
                      </div>
                    )}
                    onConfirm={(value) => {
                      saveTraitSetting(trait, "lowThreshold", Number(value));
                    }}
                  />
                </td>
              ))}
            </tr>

            <tr>
              <td className={styles["first"]}>High</td>
              {traits.map((trait) => (
                <td key={`high-${trait}`}>
                  <InlineEdit
                    defaultValue={project.traitModel[trait].highThreshold}
                    editView={({ errorMessage, ...fieldProps }) => (
                      <Textfield
                        isCompact={true}
                        {...fieldProps}
                        autoFocus
                        style={standardTextFieldStyles}
                      />
                    )}
                    readView={() => (
                      <div className={styles["trait-settings-table-read-view"]}>
                        {project.traitModel[trait].highThreshold}
                      </div>
                    )}
                    onConfirm={(value) => {
                      saveTraitSetting(trait, "highThreshold", Number(value));
                    }}
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

TraitSettings.propTypes = {
  project: PropTypes.object,
  traitType: PropTypes.object,
};
