import { useAuth0 } from "@auth0/auth0-react"
import { useMutation } from "react-query"
import { AccountsService } from "src/services/accounts.service";

export const useCreateAccount = () => {
    const auth0 = useAuth0();
    return useMutation({
        mutationFn: async () => {
            console.log('useCreateAccount invoked');
            const account = await AccountsService.createAccount(auth0);
            return account;
        }
    })
};