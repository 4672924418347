import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"
import { ProjectsService } from "src/services/projects.service"


export const useTemplateChoices = (() => {
    const auth0 = useAuth0();
    
    return useQuery({
        queryKey: 'templateChoices',
        staleTime: 120000,
        queryFn: async () => {
            const templateChoices = await ProjectsService.getProjectsTemplateChoices(auth0);
            return templateChoices;
        }
    })
});