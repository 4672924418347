import styles from "./index.module.scss";

import Select, { SingleValue } from "react-select";

import {
  TemplateChoice,
  TemplateVariantChoice,
} from "src/models/shared/projects";
import { Participant } from "src/models/shared/participants";

type OptionType = { value: TemplateVariantChoice; label: string };

interface ComponentProps {
  template: TemplateChoice;
  participant: Participant;
  onSelectChanged: (participant: Participant) => Promise<void>;
}

export const TemplateVariantSelect = ({
  template,
  participant,
  onSelectChanged,
}: ComponentProps): JSX.Element => {
  if (!participant.templateVariantId && template) {
    participant.templateVariantId = template.variants[0]?._id;
  }

  const onChange = (newValue: SingleValue<OptionType>) => {
    if (participant.templateVariantId !== newValue!.value._id) {
      participant.templateVariantId = newValue!.value._id;
      onSelectChanged(participant);
    }
  };

  const variantOptions: OptionType[] = (template?.variants || []).map((v) => ({
    value: v,
    label: v.name,
  }));

  const targetHeight = 26;
  const selectStyles = {
    control: (base: any) => ({
      ...base,
      width: "95px",
      minHeight: "initial",
    }),
    valueContainer: (base: any) => ({
      ...base,
      height: `${targetHeight - 1 - 1}px`,
      padding: "0 8px",
    }),
    clearIndicator: (base: any) => ({
      ...base,
      padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
    }),
  };

  return (
    <div className={styles["template-variant-select-list"]}>
      <Select
        styles={selectStyles}
        menuPlacement="top"
        captureMenuScroll={true}
        options={variantOptions}
        defaultValue={
          {
            value: template?.variants?.find(
              (v) => v._id === participant.templateVariantId
            ),
            label:
              template?.variants?.find(
                (v) => v._id === participant.templateVariantId
              )?.name || "default",
          } as OptionType
        }
        onChange={onChange}
      />
    </div>
  );
};
