import { ApiResponse } from "../models/api-response";
import { callExternalApi } from "./external-api.service";
import { Auth0ContextInterface } from "@auth0/auth0-react";
import { Participant, ReportEntry } from "src/models/shared/participants";
import axios, { AxiosRequestConfig } from "axios";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
type ReportSignedUrls = {
  reportUrls?: { slideSet?: string; reportUrl: string; pptxUrl?: string }[];
  analysisResultUrl?: string;
};
type ReportUploadUrlResponse = { uploadUrl: string; fileKey: string };
type PhotoUploadUrlResponse = { uploadUrl: string; filename: string };
export class ParticipantsDashboardData {
  count: number = 0;
}
export class ParticipantsService {
  public static async getParticipants(
    accountId: string,
    projectId: string,
    auth0: Auth0ContextInterface
  ): Promise<Participant[]> {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/participants?accountId=${accountId}&projectId=${projectId}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } = (await callExternalApi<Participant[]>({
      config,
      auth0,
    })) as ApiResponse<Participant[]>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }

  public static async getParticipantReportSignedUrls(
    participantId: string,
    auth0: Auth0ContextInterface
  ): Promise<ReportSignedUrls> {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/participants/${participantId}/report-signed-urls`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } = (await callExternalApi<ReportSignedUrls>({
      config,
      auth0,
    })) as ApiResponse<ReportSignedUrls>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }

  static async getParticipantUploadReportUrl(auth0: Auth0ContextInterface) {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/participants/upload-report-url`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } = (await callExternalApi<ReportUploadUrlResponse>({
      config,
      auth0,
    })) as ApiResponse<ReportUploadUrlResponse>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }

  static async getParticipantUploadPhotoUrl(
    participantId: string,
    filename: string,
    auth0: Auth0ContextInterface
  ) {
    const config: AxiosRequestConfig = {
      url:
        `${apiServerUrl}/participants/${participantId}/upload-photo-url?filename=` +
        encodeURIComponent(filename),
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } = (await callExternalApi<PhotoUploadUrlResponse>({
      config,
      auth0,
    })) as ApiResponse<PhotoUploadUrlResponse>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }

  static async uploadFileToSignedUrl(
    file: File,
    uploadUrl: string,
    onProgressChange = (progressPercentage: number) => {}
  ) {
    const parseProgress = (progressEvent: any) => {
      const progressPercentage =
        (progressEvent.loaded / progressEvent.total) * 100;
      onProgressChange(progressPercentage);
    };

    /*const formData = new FormData();
        formData.append("Content-Type", 'application/pdf');
        formData.append("file", file);

        

        await axios.post(uploadUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: parseProgress,
        })*/

    await axios.put(uploadUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress: parseProgress,
    });
  }

  static async createParticipantFromInputReport(
    fileKey: string,
    accountId: string,
    projectId: string,
    auth0: Auth0ContextInterface
  ) {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/participants/from-report`,
      method: "POST",
      data: {
        fileKey,
        accountId,
        projectId,
      },
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } = (await callExternalApi<Participant>({
      config,
      auth0,
    })) as ApiResponse<Participant>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }

  static async generateParticipantReport(
    participantId: string,
    auth0: Auth0ContextInterface
  ) {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/participants/${participantId}/report`,
      method: "PATCH",
      data: {},
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } = (await callExternalApi<Participant>({
      config,
      auth0,
    })) as ApiResponse<Participant>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }

  static async exportParticipantReportToPPTX(
    participantId: string,
    reportEntry: ReportEntry,
    auth0: Auth0ContextInterface
  ) {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/participants/${participantId}/report/pptx-export`,
      method: "PATCH",
      data: reportEntry,
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } = (await callExternalApi<ReportEntry>({
      config,
      auth0,
    })) as ApiResponse<ReportEntry>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }

  public static async getParticipantsDashboardData(
    auth0: Auth0ContextInterface
  ): Promise<ParticipantsDashboardData> {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/participants/dashboard-data`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } = (await callExternalApi<ParticipantsDashboardData>({
      config,
      auth0,
    })) as ApiResponse<ParticipantsDashboardData>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }

  static async updateParticipant(
    participantId: string,
    updates: Partial<Participant>,
    auth0: Auth0ContextInterface
  ): Promise<Participant> {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/participants/${participantId}`,
      method: "PUT",
      data: updates,
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } = (await callExternalApi<Participant>({
      config,
      auth0,
    })) as ApiResponse<Participant>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }

  public static async deleteParticipant(
    participantId: string,
    auth0: Auth0ContextInterface
  ): Promise<void> {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/participants/${participantId}`,
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
    };

    const { error } = (await callExternalApi<void>({
      config,
      auth0,
    })) as ApiResponse<void>;

    if (error) {
      throw new Error(error.message);
    }
  }
}
