import { useAuth0 } from "@auth0/auth0-react"
import { useMutation } from "react-query"
import { Account } from "src/models/shared/accounts";
import { AccountsService } from "src/services/accounts.service";

interface UpdateAccountRequest {
    accountId: string; 
    updates: Partial<Account>;
}

export const useUpdateAccount = () => {
    const auth0 = useAuth0();
    return useMutation({
        mutationFn: async (request: UpdateAccountRequest) => {
            console.log(`useUpdateAccount invoked for: ${request.accountId}`, request.updates);
            const account = await AccountsService.updateAccount(request.accountId, request.updates, auth0);
            return account;
        }
    })
};