/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React, { MouseEventHandler } from "react";
// import "./style.css";
import styles from './index.module.scss';
import { Project } from "src/models/shared/projects";

import Select, {
  components,
  MultiValueGenericProps,
  MultiValueProps,
  OnChangeValue,
  Props,
} from 'react-select';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortEndHandler,
  SortableHandle,
} from 'react-sortable-hoc';
import { useConsultants } from "src/hooks/use-consultants";
import { Consultant } from "src/models/shared/consultant";
import { toast } from "react-toastify";

type OptionType = {value: Consultant, label: string};

interface ComponentProps {
  project: Project;
  onConsultantsChanged: (project: Project) => Promise<void>
}

function arrayMove<T>(array: readonly T[], from: number, to: number) {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0]
  );
  return slicedArray;
}

const SortableMultiValue = SortableElement(
  (props: MultiValueProps<OptionType>) => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const innerProps = { ...props.innerProps, onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
  }
);

const SortableMultiValueLabel = SortableHandle(
  (props: MultiValueGenericProps) => <components.MultiValueLabel {...props} />
);

const SortableSelect = SortableContainer(Select) as React.ComponentClass<
  Props<OptionType, true> & SortableContainerProps
>;


export const Consultants = ({
  project,
  onConsultantsChanged
}: ComponentProps): JSX.Element => {
  const { data: consultants, isError: consultantsIsError, error: consultantsError } = useConsultants();

  const error = consultantsIsError ? `Error fetching consultants: ${consultantsError}` : undefined;
  if(error) {
    toast.error(`An error occurred fetching consultants list: ${error.toString()}`);
  }

  if(!project.consultants) {
    project.consultants = [];
  }

  const onChange = (selectedOptions: OnChangeValue<OptionType, true>) => {
    project.consultants = selectedOptions.map(o => o.value);
    onConsultantsChanged(project);
  }

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(project.consultants, oldIndex, newIndex);
    project.consultants = newValue;
    console.log(
      'Consultants sorted:',
      newValue.map((i) => i.name)
    );
    onConsultantsChanged(project);
  };

  const consultantOptions : OptionType[] = (consultants || []).map(c => ({ 
    value: c,
    label: c.name
  }));

  return (
    <div className={styles['consultants']}>
      <div className={styles['consultants-title']}>
        Consultants:
      </div>  
      <div className={styles['consultants-list']}>
        <SortableSelect
          captureMenuScroll={true}
          useDragHandle
          // react-sortable-hoc props:
          axis="xy"
          onSortEnd={onSortEnd}
          distance={4}
          // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
          getHelperDimensions={({ node }) => node.getBoundingClientRect()}
          // react-select props:
          isMulti
          options={consultantOptions.filter(o => (project.consultants.findIndex(c => c._id === o.value._id) < 0)) }
          defaultValue={(project.consultants || []).map(c => ({value: c, label: c.name } as OptionType))}
          onChange={onChange}
          components={{
            // @ts-ignore We're failing to provide a required index prop to SortableElement
            MultiValue: SortableMultiValue,
            // @ts-ignore We're failing to provide a required index prop to SortableElement
            MultiValueLabel: SortableMultiValueLabel,
          }}
          closeMenuOnSelect={false}
        />
      </div>
    </div>
  );
};

/*Consultants.propTypes = {
  project: Project,
};*/
