import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { ProjectsService } from "src/services/projects.service";
import { Project } from "src/models/shared/projects";

interface ProjectUploadCustomSlideRequest {
  file: File;
  project: Project;
}

export const useProjectUploadCustomSlide = () => {
  const auth0 = useAuth0();
  return useMutation({
    mutationKey: Math.random().toString(), // prevent caching. call every time
    mutationFn: async (request: ProjectUploadCustomSlideRequest) => {
      console.log(
        `useProjectUploadCustomSlide invoked for: ${request.file.name}`
      );
      const toastId = toast("Uploading custom slide", {
        type: "info",
        autoClose: false,
      });
      try {
        const uploadUrlResponse =
          await ProjectsService.getProjectUploadCustomSlideUrl(
            request.project._id,
            auth0
          );
        console.log(`received upload url: ${uploadUrlResponse.uploadUrl}`);

        toast.update(toastId, { render: "Uploading pdf" });
        await ProjectsService.uploadFileToSignedUrl(
          request.file,
          uploadUrlResponse.uploadUrl,
          (progress) => {
            console.log(`file upload progress: ${progress}%`);
          }
        );
        console.log("file uploaded successfully:", uploadUrlResponse.fileKey);

        // toast.update(toastId, { render: "Updating project" });
        let project = await ProjectsService.createCustomSlide(
          request.project._id,
          request.file.name,
          uploadUrlResponse.fileKey,
          auth0
        );
        console.log("project customSlide updated", project);

        toast.update(toastId, {
          type: "success",
          render: `Custom slide uploaded successfully`,
          autoClose: 2000,
        });
        return project;
      } catch (err) {
        toast.update(toastId, {
          type: "error",
          render: `An error occurred creating custom slide\n\nPlease make sure you uploaded a valid file`,
          autoClose: false,
        });
        return null;
      }
    },
  });
};
