import { useAuth0 } from "@auth0/auth0-react"
import { useMutation } from "react-query"
import { ParticipantsService } from "src/services/participants.service";
import { toast } from 'react-toastify';
import { Participant } from "src/models/shared/participants";


interface ParticipantUploadPhotoRequest{
    file: File;
    participant: Participant; 
} 

export const useParticipantUploadPhoto = () => {
    const auth0 = useAuth0();
    return useMutation({
        mutationKey: Math.random().toString(), // prevent caching. call every time
        mutationFn: async (request: ParticipantUploadPhotoRequest) => {
            console.log(`useParticipantUploadPhoto invoked for: ${request.file.name}`);
            const toastId = toast('Preparing to upload photo', { type: 'info' , autoClose: false });
            try {
                const uploadUrlResponse = await ParticipantsService.getParticipantUploadPhotoUrl(request.participant._id, request.file.name, auth0);
                console.log(`received upload url: ${uploadUrlResponse.uploadUrl}`);
                
                toast.update(toastId, { render: 'Uploading photo' });
                await ParticipantsService.uploadFileToSignedUrl(request.file, uploadUrlResponse.uploadUrl, (progress) => { console.log(`file upload progress: ${progress}%`)});
                console.log('file uploaded successfully');
                
                toast.update(toastId, { render: 'Updating photo'});
                let participant = await ParticipantsService.updateParticipant(request.participant._id, {
                    image: uploadUrlResponse.filename
                }, auth0)
                console.log('participant photo updated', participant);
                
                toast.update(`Regenerating client-fit report for ${request.participant.name}`, { type: 'info' , autoClose: false });
                participant = await ParticipantsService.generateParticipantReport(request.participant._id, auth0);
                console.log('participant with generated report', participant);

                toast.update(toastId, { type: 'success', render: `Photo uploaded and report generated for ${participant.name}`, autoClose: 4000 });
                return participant;
            }
            catch(err) {
                toast.update(toastId, { type: 'error', render: `An error occurred processing the report\n\nPlease make sure you uploaded a valid file`,  autoClose: false });
                return null;
            }
        }
    })
};