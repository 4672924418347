/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
// import "./style.css";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import styles from './index.module.scss';

interface Props {
  className: any;
}

export const TopBar = ({
  className,
}: Props): JSX.Element => {
  const { isAuthenticated, user, logout} = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <div className={styles['top-bar']}>
        <div className={styles.flex_row}>
          <div className={styles.flex_row1}>
            <img className={styles['top-bar-logo']} src={'/assets/images/top-bar-logo.png'} alt="alt text" />
            
            <NavLink to='/' end className={({ isActive }) => isActive ? styles['top-bar-nav-active'] : styles['top-bar-nav']}>
              Dashboard
            </NavLink>
            
            <NavLink to='/accounts' end className={({ isActive }) => isActive ? styles['top-bar-nav-active'] : styles['top-bar-nav']}>
              Accounts
            </NavLink>

            <NavLink to='/projects' end className={({ isActive }) => isActive ? styles['top-bar-nav-active'] : styles['top-bar-nav']}>
              Projects
            </NavLink>
          </div>

          <div className={styles['top-bar-profile']}>
            <img className={styles['profile-icon']} src={'/assets/images/profile_icon.png'} alt="alt text" />
            <h2 className={styles['profile-name']}>{user?.name || 'Not Logged In'}</h2>
            { isAuthenticated && 
              <button className={styles['top-bar-logout-button']} onClick={handleLogout}>
                Log Out
              </button>
            }
          </div>
        </div>
      </div>

  );
};

TopBar.propTypes = {
};
