import React, { useContext, useState } from "react";
import { TopBar } from "../../components/TopBar";

import InlineEdit from "@atlaskit/inline-edit";
import Textfield from "@atlaskit/textfield";
import TextArea from "@atlaskit/textarea";
import { FileUploader } from "react-drag-drop-files";

import { IconButton } from "@atlaskit/button/new";
import MoreIcon from "@atlaskit/icon/glyph/more";
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from "@atlaskit/dropdown-menu";

import cn from "classnames";
import styles from "./index.module.scss";

import { Account } from "../../models/shared/accounts";
import { ReactComponent as AccountIcon } from "../../icons/ph--bank-light.svg";
import { ReactComponent as ProjectIcon } from "../../icons/eos-icons--project-outlined.svg";
import { ReactComponent as ParticipantsIcon } from "../../icons/material-symbols--person-outline.svg";
// import { ReactComponent as ReplaceIcon } from '../../icons/tabler--replace.svg'

import { AppContext } from "src/app";
import { useAccounts } from "src/hooks/use-accounts";
import { useProjects } from "src/hooks/use-projects";
import { useProjectsParticipantsCount } from "src/hooks/use-projects-participants-count";
import { DateTime } from "luxon";
import { useUpdateAccount } from "src/hooks/use-update-account";
import { toast } from "react-toastify";
import { useCreateAccount } from "src/hooks/use-create-account";
import { useAccountUploadLogo } from "src/hooks/use-account-upload-logo";
import { useCreateProject } from "src/hooks/use-create-project";
import { useNavigate } from "react-router-dom";
import { Project } from "src/models/shared/projects";
import { DeleteConfirmationDialog } from "src/components/DeleteConfirmationDialog";
import { useDeleteProject } from "src/hooks/use-delete-project";
import { useDeleteAccount } from "src/hooks/use-delete-account";

export const AccountsPage = (): JSX.Element => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteModalItem, setDeleteModalItem] = useState({
    type: "project",
    id: "",
    name: "",
  });

  const navigate = useNavigate();
  const {
    data: accounts,
    isError: accountsIsError,
    error: accountsError,
    refetch: refetchAccounts,
  } = useAccounts();
  const {
    currentAccount,
    currentProject,
    setCurrentAccount,
    setCurrentProject,
  } = useContext(AppContext);
  const {
    data: projects,
    isError: projectsIsError,
    error: projectsError,
    refetch: refetchProjects,
  } = useProjects(currentAccount?._id);
  const {
    data: projectsParticipantsCount,
    isError: participantsCountIsError,
    error: participantsCountError,
  } = useProjectsParticipantsCount((projects || []).map((p) => p._id));
  const { mutateAsync: invokeUpdateAccount } = useUpdateAccount();
  const { mutateAsync: invokeCreateAccount } = useCreateAccount();
  const { mutateAsync: invokeAccountUploadLogo } = useAccountUploadLogo();
  const { mutateAsync: invokeCreateProject } = useCreateProject();
  const { mutateAsync: invokeDeleteProject } = useDeleteProject();
  const { mutateAsync: invokeDeleteAccount } = useDeleteAccount();

  const [replacingLogo, setReplacingLogo] = useState<boolean>(false);

  const error = accountsIsError
    ? `Error fetching accounts: ${accountsError}`
    : projectsIsError
    ? `Error fetching projects: ${projectsError}`
    : participantsCountIsError
    ? `Error fetching participants count: ${participantsCountError}`
    : undefined;

  const handleAccountClick = async (account: Account) => {
    setCurrentAccount(account);
  };

  const newAccountClicked = async () => {
    try {
      //const toastId = toast('Creating new account');
      const created = await invokeCreateAccount();
      setCurrentAccount(created);
      await refetchAccounts();
      //toast.dismiss(toastId);
    } catch (err) {
      toast("An error occurred creating new account", {
        type: "error",
        autoClose: false,
      });
    }
  };

  const saveAccountName = async (value: string) => {
    if (value === currentAccount?.name) return;
    console.log("saveAccountName", value);
    await _saveAccountChanges({
      name: value,
    });
  };

  const saveAccountOwner = async (value: string) => {
    if (value === currentAccount?.owner) return;
    console.log("saveAccountOwner", value);
    await _saveAccountChanges({
      owner: value,
    });
  };

  const saveAccountNotes = async (value: string) => {
    if (value === currentAccount?.notes) return;
    console.log("saveAccountNotes", value);
    await _saveAccountChanges({
      notes: value,
    });
  };

  const _saveAccountChanges = async (updates: Partial<Account>) => {
    try {
      //const toastId = toast('Saving Changes');
      const updated = await invokeUpdateAccount({
        accountId: currentAccount!._id,
        updates,
      });
      setCurrentAccount(updated);
      await refetchAccounts();
      //toast.dismiss(toastId);
    } catch (err) {
      toast("An error occurred saving changes", {
        type: "error",
        autoClose: false,
      });
    }
  };

  const handleLogoFileUpdated = async (file: File) => {
    console.log("got logo file", file);
    const updated = await invokeAccountUploadLogo({
      file,
      accountId: currentAccount!._id,
    });
    setCurrentAccount(updated);
    await refetchAccounts();
    setReplacingLogo(false);
  };

  /*const replaceLogoClicked = () => {
    setReplacingLogo(true);
  }*/

  const newProjectClicked = async () => {
    try {
      //const toastId = toast('Creating new Project');
      const created = await invokeCreateProject(currentAccount!._id);
      setCurrentProject(created);
      await refetchProjects();
      //toast.dismiss(toastId);
      navigate("/projects");
    } catch (err) {
      toast("An error occurred creating project", {
        type: "error",
        autoClose: false,
      });
    }
  };

  const handleProjectClicked = (project: Project) => {
    setCurrentProject(project);
    navigate("/projects");
  };

  const deleteProjectClicked = (project: Project) => {
    setDeleteModalItem({
      type: "project",
      id: project._id,
      name: project.name || "New Project",
    });
    setIsDeleteModalOpen(true);
  };

  const deleteAccountClicked = (account?: Account) => {
    if (account) {
      setDeleteModalItem({
        type: "account",
        id: account._id,
        name: account.name || "New Account",
      });
      setIsDeleteModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setDeleteModalItem({
      type: "project",
      id: "",
      name: "",
    });
    setIsDeleteModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    // Perform actual delete logic here
    if (deleteModalItem.type === "project") {
      console.log(`deleting project ${deleteModalItem.id}`);
      await invokeDeleteProject(deleteModalItem.id);
      console.log(`deleted project ${deleteModalItem.id}`);
      if (currentProject?._id === deleteModalItem.id) {
        setCurrentProject(undefined);
      }
      await refetchProjects();
    } else if (deleteModalItem.type === "account") {
      console.log(`deleting account ${deleteModalItem.id}`);
      await invokeDeleteAccount(deleteModalItem.id);
      console.log(`deleted account ${deleteModalItem.id}`);
      if (currentAccount?._id === deleteModalItem.id) {
        setCurrentAccount(undefined);
        setCurrentProject(undefined);
      }
      await refetchAccounts();
      await refetchProjects();
    }
    handleCloseModal();
  };

  const titleTextFieldStyles = {
    font: '400 28px/1.21 "Inter", Helvetica, Arial, serif',
  };
  const standardTextFieldStyles = {
    font: '400 16px/1.2 "Inter", Helvetica, Arial, serif',
  };
  const standardTextAreaStyles = {
    font: '400 16px/1.2 "Inter", Helvetica, Arial, serif',
  };

  return (
    <div className={cn(styles.root, "accounts-screen", "accounts")}>
      <TopBar className="top-bar-instance" />
      {error && <div className={styles["error-message"]}>{error}</div>}
      <DeleteConfirmationDialog
        isOpen={isDeleteModalOpen}
        itemName={deleteModalItem.name}
        itemType={deleteModalItem.type}
        onRequestClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
      />
      <div className={styles["main-content-container"]}>
        <div className={styles["left-column-container"]}>
          <h2 className={styles["left-column-title_box"]}>
            <span className={styles["left-column-title"]}>
              <span className={styles["left-column-title_span0"]}>
                Total Records
              </span>
              <span className={styles["left-column-title_span1"]}>
                : {accounts?.length || 0}
              </span>
            </span>
          </h2>

          <div className={styles["left-column-content"]}>
            <div className={styles["accounts-list-container"]}>
              <div className={styles["accounts-list-title"]}>
                <h2 className={styles["title-text"]}>Accounts</h2>
                <div
                  className={styles["new-account-button"]}
                  onClick={() => newAccountClicked()}
                >
                  New
                </div>
              </div>

              <div className={styles["accounts-list"]}>
                {(accounts || []).map((account) => (
                  <h2
                    key={account._id}
                    className={
                      currentAccount?._id === account._id
                        ? styles["accounts-list-item-active"]
                        : styles["accounts-list-item"]
                    }
                    onClick={() => handleAccountClick(account)}
                  >
                    {account.name || "New Account"}
                  </h2>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className={styles["right-column-container"]}>
          <div className={styles["right-column-top-spacer"]}></div>

          <div className={styles["right-column-content-container"]}>
            <div className={styles["account-details-container"]}>
              <div className={styles["account-details-main"]}>
                <div className={styles["account-details-title-row"]}>
                  <div className={styles["account-details-name-container"]}>
                    <AccountIcon className={styles["account-details-icon"]} />
                    <div className={styles["account-details-title"]}>
                      <InlineEdit
                        defaultValue={currentAccount?.name}
                        editView={({ errorMessage, ...fieldProps }) => (
                          <Textfield
                            isCompact={true}
                            {...fieldProps}
                            autoFocus
                            style={titleTextFieldStyles}
                            width={"300px"}
                          />
                        )}
                        readView={() => (
                          <div className={styles["read-view-content"]}>
                            {currentAccount?.name ||
                              "Click to enter account name"}
                          </div>
                        )}
                        onConfirm={(value) => {
                          saveAccountName(value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles["account-logo-positioner"]}>
                    <div className={styles["account-logo-actions-col"]}>
                      {currentAccount?.logo && !replacingLogo ? (
                        <img
                          className={styles["account-logo-image"]}
                          src={currentAccount?.logo}
                          alt="alt text"
                          onClick={() => setReplacingLogo(true)}
                        />
                      ) : (
                        currentAccount && (
                          <div className={styles["uploader-layer"]}>
                            <FileUploader
                              handleChange={handleLogoFileUpdated}
                              name="file"
                              types={["jpg", "jpeg", "png"]}
                            />
                            <div
                              className={styles["uploader-cancel-container"]}
                            >
                              <div
                                className={styles["uploader-cancel"]}
                                onClick={() => setReplacingLogo(false)}
                              >
                                cancel
                              </div>
                            </div>
                          </div>
                        )
                      )}
                      <div className={styles["account-details-actions"]}>
                        <DropdownMenu<HTMLButtonElement>
                          trigger="Actions"
                          shouldRenderToParent={true}
                        >
                          <DropdownItemGroup>
                            <DropdownItem
                              onClick={() =>
                                deleteAccountClicked(currentAccount)
                              }
                            >
                              Delete Account
                            </DropdownItem>
                          </DropdownItemGroup>
                        </DropdownMenu>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles["account-details-attributes-container"]}>
                  <div className={styles["account-details-attribute-row"]}>
                    <h2 className={styles["account-details-attribute-name"]}>
                      Account Owner:{" "}
                    </h2>
                    <div
                      className={
                        styles["account-details-attribute-value-editable"]
                      }
                    >
                      <InlineEdit
                        defaultValue={currentAccount?.owner}
                        editView={({ errorMessage, ...fieldProps }) => (
                          <Textfield
                            width="300px"
                            isCompact={true}
                            {...fieldProps}
                            autoFocus
                            style={standardTextFieldStyles}
                          />
                        )}
                        readView={() => (
                          <div className={styles["read-view-content"]}>
                            {currentAccount?.owner ||
                              "Click to enter account owner"}
                          </div>
                        )}
                        onConfirm={(value) => {
                          saveAccountOwner(value);
                        }}
                      />
                    </div>
                    <div
                      className={
                        styles["account-details-attribute-value-spacer"]
                      }
                    ></div>
                  </div>

                  <div className={styles["account-details-attribute-row"]}>
                    <h2 className={styles["account-details-attribute-name"]}>
                      Creation Date:
                    </h2>
                    <h2 className={styles["account-details-attribute-value"]}>
                      <div className={styles["non-editable-content"]}>
                        {currentAccount
                          ? _formatDate(currentAccount.createdAt)
                          : "N/A"}
                      </div>
                    </h2>
                  </div>

                  <div className={styles["account-details-attribute-row"]}>
                    <h2 className={styles["account-details-attribute-name"]}>
                      Notes:
                    </h2>
                    <div
                      className={
                        styles["account-details-attribute-value-editable"]
                      }
                    >
                      <InlineEdit
                        defaultValue={currentAccount?.notes}
                        editView={({ errorMessage, ...fieldProps }, ref) => (
                          // @ts-ignore - textarea does not pass through ref as a prop
                          <TextArea
                            {...fieldProps}
                            ref={ref}
                            isCompact={true}
                            style={standardTextAreaStyles}
                          />
                        )}
                        readView={() => (
                          <div className={styles["read-view-content"]}>
                            {currentAccount?.notes ||
                              "Click to enter account notes"}
                          </div>
                        )}
                        onConfirm={(value) => {
                          saveAccountNotes(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles["account-details-projects"]}>
                <div className={styles["account-details-projects-header"]}>
                  <div
                    className={
                      styles["account-projects-cell-name"] +
                      " " +
                      styles["account-projects-header-cell"]
                    }
                  >
                    <ProjectIcon className={styles["projects-icon"]} />
                    <h1 className={styles["title-text"]}>Projects</h1>
                  </div>
                  <div
                    className={
                      styles["account-projects-cell-count"] +
                      " " +
                      styles["account-projects-header-cell"]
                    }
                  >
                    <ParticipantsIcon className={styles["participants-icon"]} />
                  </div>
                  <div
                    className={styles["account-projects-cell-actions"]}
                  ></div>
                  <div
                    className={
                      styles["account-projects-cell-date"] +
                      " " +
                      styles["account-projects-header-cell"]
                    }
                  >
                    <div
                      className={styles["new-project-button"]}
                      onClick={() => newProjectClicked()}
                    >
                      New Project
                    </div>
                  </div>
                </div>

                {projects && projects.length > 0 ? (
                  <div className={styles["account-details-projects-rows"]}>
                    {projects.map((project) => (
                      <div
                        key={project._id}
                        className={styles["account-projects-row"]}
                      >
                        <div
                          className={styles["account-projects-cell-name"]}
                          onClick={() => {
                            handleProjectClicked(project);
                          }}
                        >
                          {project.name || "New Project"}
                        </div>
                        <div className={styles["account-projects-cell-count"]}>
                          <div
                            className={
                              styles["account-projects-cell-count-justify"]
                            }
                          >
                            {projectsParticipantsCount?.find(
                              (p) => p.projectId === project._id
                            )?.count || 0}
                          </div>
                        </div>
                        <div
                          className={styles["account-projects-cell-actions"]}
                        >
                          <DropdownMenu<HTMLButtonElement>
                            trigger={({ triggerRef, ...props }) => (
                              <IconButton
                                {...props}
                                icon={MoreIcon}
                                label="more"
                                ref={triggerRef}
                              />
                            )}
                            spacing="compact"
                            shouldRenderToParent={true}
                          >
                            <DropdownItemGroup>
                              <DropdownItem
                                onClick={() => deleteProjectClicked(project)}
                              >
                                Delete Project
                              </DropdownItem>
                            </DropdownItemGroup>
                          </DropdownMenu>
                        </div>
                        <div className={styles["account-projects-cell-date"]}>
                          {_formatDate(project.createdAt)}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={styles["accounts-projects-not-found"]}>
                    No Projects
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function _formatDate(date: string | Date) {
  if (typeof date === "string") {
    return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
  } else {
    return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT);
  }
}
