import { useAuth0 } from "@auth0/auth0-react";
import { useContext } from "react";
import { useQuery } from "react-query";
import { AppContext } from "src/app";
import { ProjectsService } from "src/services/projects.service";

export const useProjects = (accountId?: string) => {
  const auth0 = useAuth0();
  const { currentProject, setCurrentProject } = useContext(AppContext);
  console.log(`useProjects: ${accountId}`);
  return useQuery({
    queryKey: ["projects", accountId],
    queryFn: async () => {
      console.log("fetching projects");
      const projects = accountId
        ? await ProjectsService.getProjects(accountId, auth0)
        : [];
      return projects;
    },
    onSuccess(data) {
      if (!currentProject || currentProject.accountId !== accountId) {
        const lastProjectFromStorage = localStorage.getItem("last-project");
        console.log(`last project id from storage: ${lastProjectFromStorage}`);
        const lastProject = data.find((a) => a._id === lastProjectFromStorage);
        if (lastProject) {
          console.log(`restored last project: ${lastProject._id}`);
          setCurrentProject(lastProject);
        } else if (data.length > 0) {
          setCurrentProject(data[0]);
        } else {
          setCurrentProject(undefined);
        }
      }
    },
  });
};
