import { useContext } from "react";
import { TopBar } from "../../components/TopBar";
// import "./style.css";

import cn from 'classnames';
import styles from './index.module.scss';
import { DateTime } from 'luxon';

import { Project } from "../../models/shared/projects";
import { ReactComponent as AccountIcon } from '../../icons/ph--bank-light.svg'
import { ReactComponent as ProjectIcon } from '../../icons/eos-icons--project-outlined.svg'
import { ReactComponent as ParticipantsIcon } from '../../icons/material-symbols--person-outline.svg'

import { useDashboardData } from "src/hooks/use-dashboard-data";
import { Account } from "src/models/shared/accounts";
import { AppContext } from "src/app";
import { useNavigate } from "react-router-dom";
import { useAccounts } from "src/hooks/use-accounts";

export const DashboardPage = (): JSX.Element => {
  const { data: accounts } = useAccounts();
  const { data, isError, error } = useDashboardData();
  const { setCurrentAccount, setCurrentProject } = useContext(AppContext);
  const navigate = useNavigate();
  
  const handleAccountClick = async (account:Account) => {
    setCurrentAccount(account);
    navigate('/accounts');
  }

  const handleProjectClick = async (project:Project) => {
    const account = getProjectAccount(project)
    setCurrentAccount(account);
    setCurrentProject(project);
    navigate('/projects');
  }

  const getProjectAccount = (project: Project) => {
    return accounts?.find(a => a._id === project.accountId);
  }


  return (
    <div className={cn(styles.root, 'dashboard-screen', 'projects')}>
      <TopBar className="top-bar-instance" />
      { isError && <div className={styles['error-message']}>{`${error}`}</div> }
      <div className={styles['dashboard-widgets-container']}>
        
        <div className={styles['dashboard-row']}>
          <div className={styles['dashboard-container-grow']}>
            <div className={styles['latest-projects-row']}>
              <div className={styles['latest-projects-name']}>
                <div className={styles['quick-row']}>
                  <ProjectIcon className={styles['project-details-icon']}/> 
                  <h2 className={styles['title-text']}>Latest Projects</h2>
                </div>
              </div>
              <div className={styles['latest-projects-participants']}>
                <ParticipantsIcon className={styles['participants-icon']} />
              </div>
              <div className={styles['latest-projects-date']}>
                <h2 className={styles['standard-text']}>Created On</h2>
              </div>
            </div>

            <div className={styles['latest-projects-container']}>
              { (data?.projects.latest || []).map((project) =>
                <div key={project._id} className={styles['latest-projects-row']}>
                  <div className={styles['latest-projects-name']} onClick={() => {handleProjectClick(project)}}>
                    {getProjectAccount(project)?.name || 'New Account'}: {project.name || 'New Project'}
                  </div>
                  <div className={styles['latest-projects-participants']}>
                    {data?.projects.projectCounts.find(pc=>pc._id===project._id)?.count.toString() || '0'}
                  </div>
                  <div className={styles['latest-projects-date']}>
                    {_formatDate(project.createdAt)}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={`${styles['dashboard-container-single']} ${styles['dashboard-widget-highlighted1']}`}>
            <div className={styles['dashboard-stat-number']}>{data?.projects.count || 0}</div>
            <div className={styles['dashboard-stat-data-row']}>
              <ProjectIcon className={styles['project-details-icon']}/>
              <h2 className={styles['title-text']}>Projects</h2>
            </div>
          </div>
        </div>
        
        <div className={styles['dashboard-row']}>
          
          <div className={styles['dashboard-container-grow']}>
            <div className={styles['quick-row']}>
              <AccountIcon className={styles['account-details-icon']}/> 
              <h2 className={styles['title-text']}>Latest Accounts</h2>
            </div>

            <div className={styles['latest-accounts-container']}>
              { (data?.accounts.latest || []).map((account) =>
                <div key={account._id} className={styles['latest-accounts-row']}>
                  <div className={styles['latest-accounts-name']} onClick={() => {handleAccountClick(account)}}>
                    {account.name || 'New Account'}
                  </div>
                  <div className={styles['latest-accounts-date']}>
                    {_formatDate(account.createdAt)}
                  </div>
                </div>
              )}
            </div>

          </div>

          <div className={`${styles['dashboard-container-single']} ${styles['dashboard-widget-highlighted1']}`}>
            <div className={styles['dashboard-stat-number']}>{data?.accounts.count || 0}</div>
            <div className={styles['dashboard-stat-data-row']}>
              <AccountIcon className={styles['account-details-icon']}/>
              <h2 className={styles['title-text']}>Accounts</h2>
            </div>
          </div>

          <div className={`${styles['dashboard-container-single']} ${styles['dashboard-widget-highlighted2']}`}>
            <div className={styles['dashboard-stat-number']}>{data?.participants.count || 0}</div>
            <div className={styles['dashboard-stat-data-row']}>
              <ParticipantsIcon className={styles['participants-icon']}/>
              <h2 className={styles['title-text']}>Reports</h2>
            </div>  
          </div>
        </div>
      
      </div>
    </div>
  );
};

function _formatDate(date: string | Date) {
  if(typeof(date) === 'string') {
    return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
  }
  else {
    return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT);
  }
}
