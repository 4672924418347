import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "react-query";
import { AccountsService } from "src/services/accounts.service";

export const useDeleteAccount = () => {
  const auth0 = useAuth0();
  return useMutation({
    mutationFn: async (accountId: string) => {
      console.log("useDeleteAccount invoked");
      await AccountsService.deleteAccount(accountId, auth0);
    },
  });
};
