import { AxiosRequestConfig } from "axios";
import { ApiResponse } from "../models/api-response";
import { callExternalApi } from "./external-api.service";
import { Auth0ContextInterface } from "@auth0/auth0-react";
import { Consultant } from "../models/shared/consultant";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export class ConsultantsService {
  public static async getConsultants(
    auth0: Auth0ContextInterface
  ): Promise<Consultant[]> {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/consultants`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } = (await callExternalApi<Consultant[]>({
      config,
      auth0,
    })) as ApiResponse<Consultant[]>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }
}
