/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React, { useEffect, useState } from "react";
// import "./style.css";
import styles from "./index.module.scss";
import { Project } from "src/models/shared/projects";
import { TraitId, TraitType } from "src/models/shared/traits";
import { TraitSettings } from "../TraitSettings";
import { CustomValues } from "../CustomValues";
//import { Consultants } from "../Consultants";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { ProjectSlides } from "../ProjectSlides";

interface Props {
  project: Project;
  onTraitModelValueChanged: (
    project: Project,
    traitId: TraitId,
    settingName: "weight" | "lowThreshold" | "highThreshold",
    settingValue: number
  ) => Promise<void>;
  onCustomValuesChanged: (project: Project) => Promise<void>;
  onCustomSlidesChanged: (project: Project) => Promise<void>;
  onSlideSetsChanged: (project: Project, refresh: boolean) => Promise<void>;
}

export const ProjectSettings = ({
  project,
  onTraitModelValueChanged,
  onCustomValuesChanged,
  onCustomSlidesChanged,
  onSlideSetsChanged,
}: //onConsultantsChanged
Props): JSX.Element => {
  // because saving project (e.g. save changed value) causes a full refresh, we need to persist the state of the expanded setting "elsewhere" as long as the project id has not changed.
  // once project id changed, default again to false
  if (localStorage.getItem("project-settings-project-id") !== project._id) {
    localStorage.setItem("project-settings-tab-index", "0");
  }
  const [tabIndex, setTabIndex] = useState<number>(
    parseInt(localStorage.getItem("project-settings-tab-index") || "0")
  );

  localStorage.setItem("project-settings-project-id", project._id || "null");
  useEffect(() => {
    localStorage.setItem("project-settings-tab-index", tabIndex.toString());
  }, [tabIndex]);

  return (
    <div className={styles["project-settings-container"]}>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab>Thresholds</Tab>
          <Tab>Values</Tab>
          <Tab>Slides</Tab>
        </TabList>

        <TabPanel>
          <TraitSettings
            project={project}
            traitType={TraitType.Strength}
            onTraitModelValueChanged={onTraitModelValueChanged}
          ></TraitSettings>
          <TraitSettings
            project={project}
            traitType={TraitType.Challenge}
            onTraitModelValueChanged={onTraitModelValueChanged}
          ></TraitSettings>
          <TraitSettings
            project={project}
            traitType={TraitType.Motivation}
            onTraitModelValueChanged={onTraitModelValueChanged}
          ></TraitSettings>
        </TabPanel>
        <TabPanel>
          <CustomValues
            project={project}
            onCustomValuesChanged={onCustomValuesChanged}
          ></CustomValues>
        </TabPanel>
        <TabPanel>
          <ProjectSlides
            project={project}
            onSlideSetsChanged={onSlideSetsChanged}
            onCustomSlidesChanged={onCustomSlidesChanged}
          ></ProjectSlides>
        </TabPanel>
      </Tabs>

      {/* <Consultants project={project} onConsultantsChanged={onConsultantsChanged}></Consultants> */}
    </div>
  );
};

/*ProjectSettings.propTypes = {
  project: Project,
};*/
