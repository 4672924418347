import React, { useState } from "react";
import Modal from "react-modal";
import styles from "./index.module.scss";

// Set the custom app element for accessibility
Modal.setAppElement("#root");

interface DeleteConfirmationDialogProps {
  isOpen: boolean;
  itemType: string | undefined; // "project" | "account";
  itemName: string | undefined;
  onRequestClose: () => void;
  onConfirm: () => void;
}

export const DeleteConfirmationDialog: React.FC<
  DeleteConfirmationDialogProps
> = ({ isOpen, itemType, itemName, onRequestClose, onConfirm }) => {
  const [inputValue, setInputValue] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleConfirm = () => {
    if (inputValue.toLowerCase() === "delete") {
      onConfirm();
      cleanupAndClose();
    } else {
      alert('Please type "delete" to confirm.');
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      padding: "0px",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "600px",
      height: "340px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const cleanupAndClose = () => {
    setInputValue(""); // clear input for next time
    onRequestClose();
  };

  return (
    <Modal
      style={customStyles}
      isOpen={isOpen}
      onRequestClose={cleanupAndClose}
      contentLabel="Confirmation Dialog"
    >
      <div className={styles["delete-modal"]}>
        <h2>Confirm Deletion</h2>
        <div className={styles["modal-content"]}>
          {itemType === "project" ? (
            <div className={styles["modal-content-warning"]}>
              <p>
                <strong>WARNING</strong>: You are about to delete the project
                named:
              </p>
              <p>
                <strong>"{itemName}"</strong>.
              </p>
              <p>
                This operation is irreversible. The project, its settings,
                uploaded files, and all reports in it will be destroyed forever.
              </p>
            </div>
          ) : (
            <div className={styles["modal-content-warning"]}>
              <p>
                <strong>WARNING</strong>: You are about to delete the account
                named
              </p>
              <p>
                <strong>"{itemName}"</strong>.
              </p>
              <p>
                This operation is irreversible. The account, its settings,
                uploaded files, all projects and all reports within it will be
                destroyed forever.
              </p>
            </div>
          )}
          <p>
            To confirm deletion, please type the word <strong>"delete"</strong>{" "}
            below:
          </p>
          <input
            type="text"
            value={inputValue}
            onChange={handleChange}
            autoComplete="off"
            placeholder="Type 'delete' here"
          />
          <div className={styles["buttons-row"]}>
            <button
              className={styles["cancel-button"]}
              onClick={cleanupAndClose}
              style={{ marginRight: 10 }}
            >
              Cancel
            </button>
            <button
              className={styles["confirm-button"]}
              onClick={handleConfirm}
              disabled={inputValue.toLowerCase() !== "delete"}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
