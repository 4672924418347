import { useAuth0 } from "@auth0/auth0-react"
import { useMutation } from "react-query"
import { ParticipantsService } from "src/services/participants.service";

export const useParticipantReportSignedUrls = () => {
    const auth0 = useAuth0();
    return useMutation({
        mutationFn: async (participantId: string) => {
            console.log(`useParticipantReportSignedUrls invoked for: ${participantId}`);
            const signedUrls = await ParticipantsService.getParticipantReportSignedUrls(participantId, auth0);
            return signedUrls;
        }
    })
};