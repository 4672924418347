import { useAuth0 } from "@auth0/auth0-react"
import { useMutation } from "react-query"
import { ParticipantsService } from "src/services/participants.service";
import { toast } from 'react-toastify';


interface ParticipantUploadReportRequest{
    file: File;
    accountId: string; 
    projectId: string;
} 

export const useParticipantUploadInputReport = () => {
    const auth0 = useAuth0();
    return useMutation({
        mutationKey: Math.random().toString(), // prevent caching. call every time
        mutationFn: async (request: ParticipantUploadReportRequest) => {
            console.log(`useParticipantUploadReport invoked for: ${request.file.name}`);
            const toastId = toast('Preparing to upload report', { type: 'info' , autoClose: false });
            try {
                const uploadUrlResponse = await ParticipantsService.getParticipantUploadReportUrl(auth0);
                console.log(`received upload url: ${uploadUrlResponse.uploadUrl}`);
                
                toast.update(toastId, { render: 'Uploading report' });
                await ParticipantsService.uploadFileToSignedUrl(request.file, uploadUrlResponse.uploadUrl, (progress) => { console.log(`file upload progress: ${progress}%`)});
                console.log('file uploaded successfully');
                
                toast.update(toastId, { render: 'Analyzing data'});
                let participant = await ParticipantsService.createParticipantFromInputReport(uploadUrlResponse.fileKey, request.accountId, request.projectId, auth0)
                console.log('new participant created', participant);
                
                toast.update(toastId, { render: `Generating client-fit report for ${participant.name}`});
                participant = await ParticipantsService.generateParticipantReport(participant._id, auth0);
                console.log('participant with generated report', participant);
                
                toast.update(toastId, { type: 'success', render: `Client-fit report generated for ${participant.name}`, autoClose: 4000 });
                return participant;
            }
            catch(err) {
                toast.update(toastId, { type: 'error', render: `An error occurred processing the report\n\nPlease make sure you uploaded a valid flash report`,  autoClose: false });
                return null;
            }
        }
    })
};