/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
// import "./style.css";
import styles from "./index.module.scss";
import { Project } from "src/models/shared/projects";
import {
  TraitId,
  TraitType,
  ValueDefinition,
  allTraitIds,
  traitAttributes,
} from "src/models/shared/traits";
import { ReactComponent as TrashIcon } from "../../icons/iconamoon--trash.svg";
import InlineEdit from "@atlaskit/inline-edit";
import Textfield from "@atlaskit/textfield";
import Select, { StylesConfig, ActionMeta, MultiValue } from "react-select";
import chroma from "chroma-js";

type OptionType = { value: TraitId; label: string; color: string };

interface Props {
  project: Project;
  onCustomValuesChanged: (project: Project) => Promise<void>;
}

export const CustomValues = ({
  project,
  onCustomValuesChanged,
}: Props): JSX.Element => {
  const saveCustomValueName = (index: number, name: string) => {
    project.customValues[index].name = name;
    onCustomValuesChanged(project);
  };

  const newValueClicked = () => {
    project.customValues.push({
      name: "New Value",
      traits: [],
    });
    onCustomValuesChanged(project);
  };

  const deleteValueClicked = (val: ValueDefinition, index: number) => {
    if (
      window.confirm(
        `Are you sure you want to delete custom value "${val.name}"?`
      )
    ) {
      project.customValues.splice(index, 1);
      onCustomValuesChanged(project);
    }
  };

  const customValueTraitsChanged = (
    _val: ValueDefinition,
    index: number,
    changeValue: MultiValue<OptionType>,
    _action: ActionMeta<OptionType>
  ) => {
    //console.log(val, index);
    //console.log(changeValue);
    //console.log(action);
    //console.log(project.customValues);
    project.customValues[index].traits = changeValue.map((v) => v.value);
    console.log(project.customValues);
    onCustomValuesChanged(project);
  };

  const standardTextFieldStyles = {
    font: '400 16px/1.2 "Inter", Helvetica, Arial, serif',
  };

  const traitOptions = allTraitIds.map((t) => ({
    value: t,
    label: traitAttributes.get(t)!.label.find((l) => l.language === "en")!.text,
    color:
      traitAttributes.get(t)!.type === TraitType.Strength
        ? "#DC6E00"
        : traitAttributes.get(t)!.type === TraitType.Challenge
        ? "#C00000"
        : traitAttributes.get(t)!.type === TraitType.Motivation
        ? "#395D7D"
        : "red",
  }));

  const traitStyles: StylesConfig<OptionType, true> = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : data.color,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ":hover": {
        backgroundColor: data.color,
        color: "white",
      },
    }),
  };

  return (
    <div className={styles["custom-values-container"]}>
      <div className={styles["custom-values-title-row"]}>
        <div className={styles["custom-values-title"]}>Custom Values</div>
        <div
          className={styles["new-value-button"]}
          onClick={() => newValueClicked()}
        >
          New Value
        </div>
      </div>
      <div className={styles["custom-values"]}>
        {project.customValues.map((val, index) => (
          <div
            key={`customVal_${project._id}_${index}`}
            className={styles["custom-value-row"]}
          >
            <div className={styles["custom-value-name"]}>
              <InlineEdit
                defaultValue={val.name}
                editView={({ errorMessage, ...fieldProps }) => (
                  <Textfield
                    isCompact={true}
                    {...fieldProps}
                    autoFocus
                    maxLength={75}
                    style={standardTextFieldStyles}
                  />
                )}
                readView={() => (
                  <div className={styles["custom-value-read-view"]}>
                    {val.name}
                  </div>
                )}
                onConfirm={(value) => {
                  saveCustomValueName(index, value);
                }}
              />
            </div>
            <div className={styles["custom-value-traits"]}>
              <Select
                menuPlacement="top"
                captureMenuScroll={true}
                closeMenuOnSelect={false}
                defaultValue={traitOptions.filter((o) =>
                  val.traits.includes(o.value)
                )}
                isMulti
                options={traitOptions}
                styles={traitStyles}
                className={styles["custom-value-traits-select"]}
                onChange={(
                  changeValue: MultiValue<OptionType>,
                  action: ActionMeta<OptionType>
                ) => {
                  customValueTraitsChanged(val, index, changeValue, action);
                }}
              />
            </div>
            <div className={styles["custom-value-actions"]}>
              <TrashIcon
                className={styles["icon-trash"]}
                onClick={() => deleteValueClicked(val, index)}
              />
            </div>
          </div>
        ))}
        {project.customValues.length === 0 && (
          <div className={styles["custom-value-row-empty"]}>
            No custom values were configured for this project
          </div>
        )}
      </div>
    </div>
  );
};

/*CustomValues.propTypes = {
  project: Project,
};*/
