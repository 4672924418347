import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "react-query";
import { ProjectsService } from "src/services/projects.service";

export const useDeleteProject = () => {
  const auth0 = useAuth0();
  return useMutation({
    mutationFn: async (projectId: string) => {
      console.log("useDeleteProject invoked");
      await ProjectsService.deleteProject(projectId, auth0);
    },
  });
};
