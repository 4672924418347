import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiResponse } from "../models/api-response";
import { AppError } from "../models/app-error";
import { Auth0ContextInterface, OAuthError } from "@auth0/auth0-react";

export const callExternalApi = async <T>(
  options: {
    config: AxiosRequestConfig;
    auth0: Auth0ContextInterface;
  },
  isRetry = false
): Promise<ApiResponse<T>> => {
  try {
    await options.auth0.getAccessTokenSilently();
    const idToken = (await options.auth0.getIdTokenClaims())?.__raw;
    options.config.headers!.Authorization = `Bearer ${idToken}`;

    const response: AxiosResponse = await axios(options.config);
    const { data } = response;

    return {
      data,
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response?.status === 401 && isRetry === false) {
        console.log(
          "received 401 error, attempting to refresh token and retry"
        );
        await options.auth0.getAccessTokenSilently({ cacheMode: "off" });
        return await callExternalApi<T>(options, true);
      } else {
        const { response } = axiosError;

        let message = "http request failed";

        if (response && response.statusText) {
          message = response.statusText;
        }

        if (axiosError.message) {
          message = axiosError.message;
        }

        if (response && response.data && (response.data as AppError).message) {
          message = (response.data as AppError).message;
        }

        return {
          data: null,
          error: {
            message,
          },
        };
      }
    } else if (
      error instanceof OAuthError &&
      (error as OAuthError)?.error === "invalid_grant"
    ) {
      console.log(
        "invalid_grant OAuth error received, token is invalid and session must logout"
      );
      options.auth0.logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    }

    return {
      data: null,
      error: {
        message: (error as Error).message,
      },
    };
  }
};
