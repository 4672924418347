import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"
import { ProjectsService } from "src/services/projects.service";


export const useProjectsParticipantsCount = ((projectIds: string[]) => {
    const auth0 = useAuth0();
    
    return useQuery({
        queryKey: ['projects-participants-count', projectIds],
        queryFn: async () => {
            const projectsParticipantsCount = await ProjectsService.getProjectsParticipantCount(projectIds, auth0);
            return projectsParticipantsCount;
        }
    })
});