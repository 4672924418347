export enum TraitId {
  Adjustment = "Adjustment",
  Ambition = "Ambition",
  Sociability = "Sociability",
  InterpersonalSensitivity = "InterpersonalSensitivity",
  Prudence = "Prudence",
  Inquisitive = "Inquisitive",
  LearningApproach = "LearningApproach",

  Excitable = "Excitable",
  Skeptical = "Skeptical",
  Cautious = "Cautious",
  Reserved = "Reserved",
  Leisurely = "Leisurely",
  Bold = "Bold",
  Mischievous = "Mischievous",
  Colorful = "Colorful",
  Imaginative = "Imaginative",
  Diligent = "Diligent",
  Dutiful = "Dutiful",

  Recognition = "Recognition",
  Power = "Power",
  Hedonism = "Hedonism",
  Altruistic = "Altruistic",
  Affiliation = "Affiliation",
  Tradition = "Tradition",
  Security = "Security",
  Commerce = "Commerce",
  Aesthetics = "Aesthetics",
  Science = "Science",
}

export const allTraitIds = Object.keys(TraitId) as TraitId[];

export enum TraitType {
  Strength = "Strength",
  Challenge = "Challenge",
  Motivation = "Motivation",
}
export function isNegativeTraitType(traitType: TraitType) {
  return traitType === TraitType.Challenge;
}

export class LanguageLabel {
  constructor(public language: string, public text: string) {}
}

export class TraitAttributes {
  constructor(
    public label: LanguageLabel[],
    public type: TraitType,
    public alternateLabelSpellings?: string[]
  ) {}
}
export class ValueDefinition {
  constructor(public name: string, public traits: TraitId[]) {}
}

export const traitAttributes: Map<TraitId, TraitAttributes> = new Map([
  [
    TraitId.Adjustment,
    {
      label: [
        { language: "en", text: "Adjustment" },
        { language: "fr", text: "Ajustement" },
        { language: "da", text: "Følelsesmæssigt Afbalanceret" },
      ],
      type: TraitType.Strength,
    },
  ],
  [
    TraitId.Ambition,
    {
      label: [
        { language: "en", text: "Ambition" },
        { language: "fr", text: "Ambition" },
        { language: "da", text: "Udadvendt Ambitiøs" },
      ],
      type: TraitType.Strength,
    },
  ],
  [
    TraitId.Sociability,
    {
      label: [
        { language: "en", text: "Sociability" },
        { language: "fr", text: "Sociabilité" },
        { language: "da", text: "Udadvendt Selskabelig" },
      ],
      type: TraitType.Strength,
    },
  ],
  [
    TraitId.InterpersonalSensitivity,
    {
      label: [
        { language: "en", text: "Interpersonal Sensitivity" },
        { language: "fr", text: "Sensibilité Interpersonnelle" },
        { language: "da", text: "Omgængelig" },
      ],
      type: TraitType.Strength,
    },
  ],
  [
    TraitId.Prudence,
    {
      label: [
        { language: "en", text: "Prudence" },
        { language: "fr", text: "Prudence" },
        { language: "da", text: "Pålidelig" },
      ],
      type: TraitType.Strength,
    },
  ],
  [
    TraitId.Inquisitive,
    {
      label: [
        { language: "en", text: "Inquisitive" },
        { language: "fr", text: "Curiosité" },
        { language: "da", text: "Intellektuelt Åben" },
      ],
      type: TraitType.Strength,
    },
  ],
  [
    TraitId.LearningApproach,
    {
      label: [
        { language: "en", text: "Learning Approach" },
        { language: "fr", text: "Type d'apprentissage" },
        { language: "da", text: "Videnssøgende" },
      ],
      type: TraitType.Strength,
    },
  ],

  [
    TraitId.Excitable,
    {
      label: [
        { language: "en", text: "Excitable" },
        { language: "fr", text: "Versatile" },
        { language: "da", text: "Omskiftelig" },
      ],
      type: TraitType.Challenge,
    },
  ],
  [
    TraitId.Skeptical,
    {
      label: [
        { language: "en", text: "Skeptical" },
        { language: "fr", text: "Sceptique" },
        { language: "da", text: "Skeptisk" },
      ],
      type: TraitType.Challenge,
      alternateLabelSpellings: ["Sceptical"],
    },
  ],
  [
    TraitId.Cautious,
    {
      label: [
        { language: "en", text: "Cautious" },
        { language: "fr", text: "Précautionneux" },
        { language: "da", text: "Forsigtig" },
      ],
      type: TraitType.Challenge,
    },
  ],
  [
    TraitId.Reserved,
    {
      label: [
        { language: "en", text: "Reserved" },
        { language: "fr", text: "Réservé" },
        { language: "da", text: "Reserveret" },
      ],
      type: TraitType.Challenge,
    },
  ],
  [
    TraitId.Leisurely,
    {
      label: [
        { language: "en", text: "Leisurely" },
        { language: "fr", text: "Nonchalant" },
        { language: "da", text: "Modstræbende" },
      ],
      type: TraitType.Challenge,
    },
  ],
  [
    TraitId.Bold,
    {
      label: [
        { language: "en", text: "Bold" },
        { language: "fr", text: "Sûr de soi" },
        { language: "da", text: "Arrogant" },
      ],
      type: TraitType.Challenge,
    },
  ],
  [
    TraitId.Mischievous,
    {
      label: [
        { language: "en", text: "Mischievous" },
        { language: "fr", text: "Malicieux" },
        { language: "da", text: "Uforpligtet" },
      ],
      type: TraitType.Challenge,
    },
  ],
  [
    TraitId.Colorful,
    {
      label: [
        { language: "en", text: "Colorful" },
        { language: "fr", text: "Théâtral" },
        { language: "da", text: "Dramatisk" },
      ],
      type: TraitType.Challenge,
      alternateLabelSpellings: ["Colourful"],
    },
  ],
  [
    TraitId.Imaginative,
    {
      label: [
        { language: "en", text: "Imaginative" },
        { language: "fr", text: "Imaginatif" },
        { language: "da", text: "Excentrisk" },
      ],
      type: TraitType.Challenge,
    },
  ],
  [
    TraitId.Diligent,
    {
      label: [
        { language: "en", text: "Diligent" },
        { language: "fr", text: "Exigeant" },
        { language: "da", text: "Nøjeregnende" },
      ],
      type: TraitType.Challenge,
    },
  ],
  [
    TraitId.Dutiful,
    {
      label: [
        { language: "en", text: "Dutiful" },
        { language: "fr", text: "Dévoué" },
        { language: "da", text: "Afhængig" },
      ],
      type: TraitType.Challenge,
    },
  ],

  [
    TraitId.Recognition,
    {
      label: [
        { language: "en", text: "Recognition" },
        { language: "fr", text: "Reconnaissance" },
        { language: "da", text: "Status" },
      ],
      type: TraitType.Motivation,
    },
  ],
  [
    TraitId.Power,
    {
      label: [
        { language: "en", text: "Power" },
        { language: "fr", text: "Pouvoir" },
        { language: "da", text: "Magt" },
      ],
      type: TraitType.Motivation,
    },
  ],
  [
    TraitId.Hedonism,
    {
      label: [
        { language: "en", text: "Hedonism" },
        { language: "fr", text: "Hédonisme" },
        { language: "da", text: "Livsnydelse" },
      ],
      type: TraitType.Motivation,
    },
  ],
  [
    TraitId.Altruistic,
    {
      label: [
        { language: "en", text: "Altruistic" },
        { language: "fr", text: "Altruisme" },
        { language: "da", text: "Socialt Engagement" },
      ],
      type: TraitType.Motivation,
    },
  ],
  [
    TraitId.Affiliation,
    {
      label: [
        { language: "en", text: "Affiliation" },
        { language: "fr", text: "Affiliation" },
        { language: "da", text: "Relationer" },
      ],
      type: TraitType.Motivation,
    },
  ],
  [
    TraitId.Tradition,
    {
      label: [
        { language: "en", text: "Tradition" },
        { language: "fr", text: "Tradition" },
        { language: "da", text: "Tradition" },
      ],
      type: TraitType.Motivation,
    },
  ],
  [
    TraitId.Security,
    {
      label: [
        { language: "en", text: "Security" },
        { language: "fr", text: "Sécurité" },
        { language: "da", text: "Sikkerhed" },
      ],
      type: TraitType.Motivation,
    },
  ],
  [
    TraitId.Commerce,
    {
      label: [
        { language: "en", text: "Commerce" },
        { language: "fr", text: "Commerce" },
        { language: "da", text: "Økonomi" },
      ],
      type: TraitType.Motivation,
    },
  ],
  [
    TraitId.Aesthetics,
    {
      label: [
        { language: "en", text: "Aesthetics" },
        { language: "fr", text: "Esthétique" },
        { language: "da", text: "Æstetik" },
      ],
      type: TraitType.Motivation,
    },
  ],
  [
    TraitId.Science,
    {
      label: [
        { language: "en", text: "Science" },
        { language: "fr", text: "Science" },
        { language: "da", text: "Videnskab" },
      ],
      type: TraitType.Motivation,
    },
  ],
]);

export const coreValues: ValueDefinition[] = [
  {
    name: "Competition",
    traits: [
      TraitId.Ambition,
      TraitId.Recognition,
      TraitId.Power,
      TraitId.Hedonism,
      TraitId.Commerce,
    ],
  },
  {
    name: "Creativity",
    traits: [
      TraitId.Sociability,
      TraitId.Inquisitive,
      TraitId.Bold,
      TraitId.Mischievous,
      TraitId.Colorful,
      TraitId.Imaginative,
      TraitId.Aesthetics,
    ],
  },
  {
    name: "Collaboration",
    traits: [
      TraitId.InterpersonalSensitivity,
      TraitId.Excitable,
      TraitId.Skeptical,
      TraitId.Cautious,
      TraitId.Reserved,
      TraitId.Leisurely,
      TraitId.Altruistic,
      TraitId.Affiliation,
      TraitId.Tradition,
      TraitId.Security,
    ],
  },
  {
    name: "Control",
    traits: [
      TraitId.Adjustment,
      TraitId.Prudence,
      TraitId.LearningApproach,
      TraitId.Diligent,
      TraitId.Dutiful,
      TraitId.Science,
    ],
  },
];
