import axios, { AxiosRequestConfig } from "axios";
import { ApiResponse } from "../models/api-response";
import { callExternalApi } from "./external-api.service";
import { Auth0ContextInterface } from "@auth0/auth0-react";
import { Project, TemplateChoice } from "../models/shared/projects";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
type CustomSlideUploadUrlResponse = { uploadUrl: string; fileKey: string };

export class ProjectsDashboardData {
  count: number = 0;
  latest: Project[] = [];
  projectCounts: { _id: string; count: number }[] = [];
}
export class ProjectsService {
  public static async getProjects(
    accountId: string,
    auth0: Auth0ContextInterface
  ): Promise<Project[]> {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/projects?accountId=${accountId}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } = (await callExternalApi<Project[]>({
      config,
      auth0,
    })) as ApiResponse<Project[]>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }

  public static async getProjectsParticipantCount(
    projectIds: string[],
    auth0: Auth0ContextInterface
  ): Promise<{ projectId: string; count: number }[]> {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/projects/participants-count-query`,
      method: "POST",
      data: projectIds,
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } = (await callExternalApi<
      { _id: string; count: number }[]
    >({ config, auth0 })) as ApiResponse<{ _id: string; count: number }[]>;

    if (error) {
      throw new Error(error.message);
    }

    return projectIds.map((id) => {
      return {
        projectId: id,
        count: data?.find((c) => c._id === id)?.count || 0,
      };
    });
  }

  public static async getProjectsDashboardData(
    auth0: Auth0ContextInterface
  ): Promise<ProjectsDashboardData> {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/projects/dashboard-data`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } = (await callExternalApi<ProjectsDashboardData>({
      config,
      auth0,
    })) as ApiResponse<ProjectsDashboardData>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }

  public static async getProjectsTemplateChoices(
    auth0: Auth0ContextInterface
  ): Promise<TemplateChoice[]> {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/projects/template-choices`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } = (await callExternalApi<TemplateChoice[]>({
      config,
      auth0,
    })) as ApiResponse<TemplateChoice[]>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }

  static async createProject(
    accountId: string,
    auth0: Auth0ContextInterface
  ): Promise<Project> {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/projects`,
      method: "POST",
      data: { accountId },
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } = (await callExternalApi<Project>({
      config,
      auth0,
    })) as ApiResponse<Project>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }

  static async updateProject(
    projectId: string,
    updates: Partial<Project>,
    auth0: Auth0ContextInterface
  ): Promise<Project> {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/projects/${projectId}`,
      method: "PUT",
      data: updates,
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } = (await callExternalApi<Project>({
      config,
      auth0,
    })) as ApiResponse<Project>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }

  public static async deleteProject(
    projectId: string,
    auth0: Auth0ContextInterface
  ): Promise<void> {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/projects/${projectId}`,
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
    };

    const { error } = (await callExternalApi<void>({
      config,
      auth0,
    })) as ApiResponse<void>;

    if (error) {
      throw new Error(error.message);
    }
  }

  static async getProjectUploadCustomSlideUrl(
    projectId: string,
    auth0: Auth0ContextInterface
  ) {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/projects/${projectId}/upload-custom-slide-url`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } =
      (await callExternalApi<CustomSlideUploadUrlResponse>({
        config,
        auth0,
      })) as ApiResponse<CustomSlideUploadUrlResponse>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }

  static async uploadFileToSignedUrl(
    file: File,
    uploadUrl: string,
    onProgressChange = (progressPercentage: number) => {}
  ) {
    const parseProgress = (progressEvent: any) => {
      const progressPercentage =
        (progressEvent.loaded / progressEvent.total) * 100;
      onProgressChange(progressPercentage);
    };

    await axios.put(uploadUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress: parseProgress,
    });
  }

  static async createCustomSlide(
    projectId: string,
    name: string,
    pdfFileKey: string,
    auth0: Auth0ContextInterface
  ): Promise<Project> {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/projects/${projectId}/custom-slides`,
      method: "POST",
      data: {
        name,
        pdfFileKey,
      },
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } = (await callExternalApi<Project>({
      config,
      auth0,
    })) as ApiResponse<Project>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }
}
