import { useAuth0 } from "@auth0/auth0-react";
import { useContext } from "react";
import { useQuery } from "react-query";
import { AppContext } from "src/app";
import { AccountsService } from "src/services/accounts.service";

export const useAccounts = () => {
  const auth0 = useAuth0();
  const { currentAccount, setCurrentAccount } = useContext(AppContext);

  return useQuery({
    queryKey: "accounts",
    queryFn: async () => {
      console.log("fetching accounts");
      const accounts = await AccountsService.getAccounts(auth0);
      return accounts;
    },
    onSuccess(data) {
      if (!currentAccount) {
        const lastAccountFromStorage = localStorage.getItem("last-account");
        console.log(`last account id from storage: ${lastAccountFromStorage}`);
        const lastAccount = data.find((a) => a._id === lastAccountFromStorage);
        if (lastAccount) {
          console.log(`restored last account: ${lastAccount._id}`);
          setCurrentAccount(lastAccount);
        } else if (data.length > 0) {
          setCurrentAccount(data[0]);
        } else {
          setCurrentAccount(undefined);
        }
      }
    },
  });
};
