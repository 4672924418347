import { useAuth0 } from "@auth0/auth0-react"
import { useMutation } from "react-query"
import { ParticipantsService } from "src/services/participants.service";
import { toast } from 'react-toastify';


interface ParticipantReprocessReportRequest{
    participantId: string;
    participantName: string;
} 

export const useParticipantReprocessReport = () => {
    const auth0 = useAuth0();
    return useMutation({
        mutationKey: Math.random().toString(), // prevent caching. call every time
        mutationFn: async (request: ParticipantReprocessReportRequest) => {
            console.log(`useParticipantReprocessReport invoked for: ${request.participantId}`);
            
            const toastId = toast(`Regenerating client-fit report for ${request.participantName}`, { type: 'info' , autoClose: false });
            try {
                const participant = await ParticipantsService.generateParticipantReport(request.participantId, auth0);
                console.log('participant with generated report', participant);
                
                toast.update(toastId, { type: 'success', render: `Client-fit report generated for ${request.participantName}`, autoClose: 4000 });
                return participant;
            }
            catch(err) {
                toast.update(toastId, { type: 'error', render: `An error occurred processing the report\n\nPlease try again. If the failure persists - contact support.`,  autoClose: false });
                return null;
            }
        }
    })
};