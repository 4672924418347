import axios, { AxiosRequestConfig } from "axios";
import { ApiResponse } from "../models/api-response";
import { callExternalApi } from "./external-api.service";
import { Auth0ContextInterface } from "@auth0/auth0-react";
import { Account } from "../models/shared/accounts";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
export class AccountsDashboardData {
  count: number = 0;
  latest: Account[] = [];
}
type AccountLogoUploadUrlResponse = { uploadUrl: string; fileName: string };
export class AccountsService {
  public static async getAccounts(
    auth0: Auth0ContextInterface
  ): Promise<Account[]> {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/accounts`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } = (await callExternalApi<Account[]>({
      config,
      auth0,
    })) as ApiResponse<Account[]>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }

  public static async getAccountsDashboardData(
    auth0: Auth0ContextInterface
  ): Promise<AccountsDashboardData> {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/accounts/dashboard-data`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } = (await callExternalApi<AccountsDashboardData>({
      config,
      auth0,
    })) as ApiResponse<AccountsDashboardData>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }

  static async updateAccount(
    accountId: string,
    updates: Partial<Account>,
    auth0: Auth0ContextInterface
  ): Promise<Account> {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/accounts/${accountId}`,
      method: "PUT",
      data: updates,
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } = (await callExternalApi<Account>({
      config,
      auth0,
    })) as ApiResponse<Account>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }

  static async createAccount(auth0: Auth0ContextInterface): Promise<Account> {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/accounts`,
      method: "POST",
      data: {},
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } = (await callExternalApi<Account>({
      config,
      auth0,
    })) as ApiResponse<Account>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }

  static async getAccountLogoUploadUrl(
    accountId: string,
    extension: string,
    auth0: Auth0ContextInterface
  ) {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/accounts/${accountId}/logo-upload-url?ext=${extension}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    const { data, error } =
      (await callExternalApi<AccountLogoUploadUrlResponse>({
        config,
        auth0,
      })) as ApiResponse<AccountLogoUploadUrlResponse>;

    if (error) {
      throw new Error(error.message);
    }

    return data!;
  }

  static async uploadFileToSignedUrl(
    file: File,
    uploadUrl: string,
    onProgressChange = (progressPercentage: number) => {}
  ) {
    const parseProgress = (progressEvent: any) => {
      const progressPercentage =
        (progressEvent.loaded / progressEvent.total) * 100;
      onProgressChange(progressPercentage);
    };

    await axios.put(uploadUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress: parseProgress,
    });
  }

  public static async deleteAccount(
    accountId: string,
    auth0: Auth0ContextInterface
  ): Promise<void> {
    const config: AxiosRequestConfig = {
      url: `${apiServerUrl}/accounts/${accountId}`,
      method: "DELETE",
      headers: {
        "content-type": "application/json",
      },
    };

    const { error } = (await callExternalApi<void>({
      config,
      auth0,
    })) as ApiResponse<void>;

    if (error) {
      throw new Error(error.message);
    }
  }
}
