import { useAuth0 } from "@auth0/auth0-react"
import { useMutation } from "react-query"
import { ProjectsService } from "src/services/projects.service";

export const useCreateProject = () => {
    const auth0 = useAuth0();
    return useMutation({
        mutationFn: async (accountId: string) => {
            console.log('useCreateProject invoked');
            const project = await ProjectsService.createProject(accountId, auth0);
            return project;
        }
    })
};